import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { userLoadState, userUpdateUserData } from 'src/stores/actionCreators'
import { promisifyAction } from './utils'
import DateTime from './utils/DateTime'

const AuthWrapper = ({ children }) => {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const getCurrentUser = promisifyAction(dispatch, userLoadState)
  const updateCurrentUser = promisifyAction(dispatch, userUpdateUserData)

  useEffect(() => {
    ;(async () => {
      const user = await getCurrentUser()
      const localTimeZone: string = DateTime.timeZone()
      const lang = window?.navigator?.language.split('-')[0]
      if (!user.time_zone || user.time_zone !== localTimeZone) {
        updateCurrentUser({ time_zone: localTimeZone })
      }
      if (!user.browserLanguage || user.browserLanguage !== lang) {
        updateCurrentUser({ browser_language: lang })
      }
      if (!user.shyppleLanguage || user.shyppleLanguage !== i18n.language) {
        updateCurrentUser({ shypple_language: i18n.language })
      }
      setLoading(false)
    })()
  }, [])

  if (loading) return null

  return children
}

export default AuthWrapper
