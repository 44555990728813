import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { ResponseSchema } from 'src/@types/endpoints/response-schema'
import { AxiosError } from 'axios'
import endpoints, { queryKeys } from '../endpoints'
import { apiClient } from '../http-common'

type SigmaReportsResponse = ResponseSchema<{
  url: string
}>

export const useGetSigmaIframeUrl = (
  options?: UseQueryOptions<
    SigmaReportsResponse,
    AxiosError,
    SigmaReportsResponse
  >
) => {
  const url = endpoints.sigmaReports
  const { sigmaReports: key } = queryKeys

  const getIframeUrl = () => apiClient.get<any, SigmaReportsResponse>(url)
  return useQuery<SigmaReportsResponse, AxiosError>(
    [key],
    getIframeUrl,
    options
  )
}
