import { FunctionComponent, useMemo } from 'react'
import { filter } from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { Link } from '@mui/material'
import { ModalityEnum } from 'src/config/constants'
import CarbonEmissionsBlock from 'src/components/CarbonEmissionsBlock'
import { permissionTo } from '../../utils'
import { ShipmentBodyLoader } from './ShipmentOverviewLoaders'
import ShipmentOverviewShippers from './ShipmentOverviewShippers'
import ShipmentOverviewLoad from './ShipmentOverviewLoad'

type Props = { shipment: IOverviewShipment; onOpenEditShipment: () => void }

const ShipmentOverviewBody: FunctionComponent<Props> = ({
  shipment,
  onOpenEditShipment,
}) => {
  const { t } = useTranslation()
  const bookings: IBookingShort[] = filter(shipment.bookings, 'shipper_name')

  const isAirShipmentType: boolean = useMemo(() => {
    return shipment.shipment_type === ModalityEnum.Air
  }, [shipment])

  const addButton = useMemo(
    () =>
      permissionTo('shipments.manage') ? (
        <Link
          variant="body1"
          component="button"
          children={t('common.buttons.add', 'Add')}
          onClick={onOpenEditShipment}
        />
      ) : (
        '-'
      ),
    [onOpenEditShipment]
  )

  if (!shipment.id) {
    return <ShipmentBodyLoader />
  }

  const getVesselVoyageValues = () => {
    if (isAirShipmentType) {
      return shipment.voyage_no || '-'
    }
    if (!shipment.vessel) {
      return [
        addButton,
        <Box ml={1} component="span">
          {shipment.voyage_no}
        </Box>,
      ]
    }
    return [shipment.vessel, shipment.voyage_no].filter(Boolean).join(' | ')
  }

  return (
    <>
      <div className="shipment-overview-top__details">
        {permissionTo('shipments.bookings.parties.view') ? (
          <div className="shipment-overview-top__details--block">
            <div className="shipment-overview-top__details--block-title">
              <Typography
                variant="body1Strong"
                children={t('common.entities.shipper_other', 'Shippers')}
              />
            </div>
            <div className="shipment-overview-top__details--block-content">
              <Box typography="body1">
                <ShipmentOverviewShippers bookings={bookings} />
              </Box>
            </div>
          </div>
        ) : (
          ''
        )}
        <ShipmentOverviewLoad shipment={shipment} />
        {shipment.has_reefer_containers && (
          <div className="shipment-overview-top__details--block">
            <div className="shipment-overview-top__details--block-title">
              <Typography
                variant="body1Strong"
                children={t('shipment_details.product_group')}
              />
            </div>
            <div className="shipment-overview-top__details--block-content">
              <Typography
                variant="body1"
                data-testid="shipment-overview-product-group-value"
              >
                {shipment.product_group?.name || addButton}
              </Typography>
            </div>
          </div>
        )}
      </div>
      <div className="shipment-overview-top__details">
        <div className="shipment-overview-top__details--wrapper">
          <div className="shipment-overview-top__details--block">
            <div className="shipment-overview-top__details--block-title">
              <Typography
                variant="body1Strong"
                children={
                  isAirShipmentType
                    ? t('shipment_details.awb', 'AWB')
                    : t('shipment_details.master_bl', 'Master BL')
                }
              />
            </div>
            <div className="shipment-overview-top__details--block-content">
              <Typography
                variant="body1"
                data-testid="shipment-overview-bl-number-value"
              >
                {shipment.bl_number || addButton}
              </Typography>
            </div>
          </div>
          {shipment.visibility_only && (
            <div className="shipment-overview-top__details--block">
              <div className="shipment-overview-top__details--block-title">
                <Typography
                  variant="body1Strong"
                  children={t('shipment_details.booking_number')}
                />
              </div>
              <div className="shipment-overview-top__details--block-content">
                <Typography
                  variant="body1"
                  data-testid="shipment-overview-bl-number-value"
                >
                  {shipment.booking_number || addButton}
                </Typography>
              </div>
            </div>
          )}
          <div className="shipment-overview-top__details--block">
            <div className="shipment-overview-top__details--block-title">
              <Typography
                variant="body1Strong"
                children={
                  isAirShipmentType
                    ? t('common.modality_air.carrier', 'Airline')
                    : t('common.modality_sea.carrier', 'Carrier')
                }
              />
            </div>
            <div className="shipment-overview-top__details--block-content">
              <Typography
                variant="body1"
                data-testid="shipment-overview-carrier-value"
              >
                {shipment.carrier_name || addButton}
              </Typography>
            </div>
          </div>
        </div>
        <div className="shipment-overview-top__details--wrapper">
          <div className="shipment-overview-top__details--block">
            <div className="shipment-overview-top__details--block-title">
              <Typography
                variant="body1Strong"
                children={
                  isAirShipmentType
                    ? t('common.modality_air.vessel_name', 'Flight')
                    : [
                        t('common.modality_sea.vessel_name', 'Vessel'),
                        t('common.voyage_number', 'Voyage'),
                      ].join(' | ')
                }
              />
            </div>
            <div className="shipment-overview-top__details--block-content">
              <Typography
                variant="body1"
                data-testid="shipment-overview-vessel-flight-value"
              >
                {getVesselVoyageValues()}
              </Typography>
            </div>
          </div>
          {shipment.co2_emissions && (
            <div className="shipment-overview-top__details--block">
              <div className="shipment-overview-top__details--block-title">
                <Typography variant="body1Strong">
                  CO<sub>2</sub>
                </Typography>
              </div>
              <div className="shipment-overview-top__details--block-content co2">
                <CarbonEmissionsBlock shipment={shipment} />
              </div>
            </div>
          )}
          <div className="shipment-overview-top__details--block">
            <div className="shipment-overview-top__details--block-title">
              <Typography variant="body1Strong">
                {t('common.entities.incoterm_one', 'Incoterm')}
              </Typography>
            </div>
            <div className="shipment-overview-top__details--block-content">
              <Typography
                variant="body1"
                data-testid="shipment-overview-incoterm-value"
              >
                {shipment.incoterm ? shipment.incoterm.code : '-'}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShipmentOverviewBody
