import { AxiosResponse } from 'axios'
import { userNotificationSerializer } from './'

export default function (response: AxiosResponse): IUser {
  const data = response.data.data
  const profile = data.profile
  return {
    shyppleLanguage: data.shypple_language,
    browserLanguage: data.browser_language,
    avatar: data.avatar,
    configured_po_upload: data.configured_po_upload,
    city: profile.city,
    companyName: profile.company_name,
    countryId: profile.country_id,
    email: data.email,
    firstName: data.first_name,
    isActive: data.is_active,
    isDummy: data.dummy,
    canBeMentioned: data.can_be_mentioned,
    inttraCompanyId: data.inttra_company_id,
    watchShipmentSetting: data.watch_shipment_setting,
    watchShipmentRules: data.watch_shipment_rules,
    isOrdersImportActive: data.is_order_import_active,
    isStaff: data.is_staff,
    isSuperUser: data.is_super_user,
    lastName: data.last_name,
    phone: data.phone,
    postalCode: profile.postal_code,
    street: profile.street,
    tokenType: response.headers.tokenType,
    username: data.username,
    vatNumber: profile.vat_number,
    id: data.id,
    createdAt: data.created_at,
    organizationName: data.organization_name,
    customerState: data.customer_state,
    permissions: data.permissions,
    notifications: data.user_notifications
      ? data.user_notifications.map(userNotificationSerializer)
      : [],
    milestones: data.user_milestones ? data.user_milestones : [],
    organizationId: data.organization_id,
    organizationRole: data.organization_role,
    organizationRoleId: data.organization_role_id,
    userRole: data.user_role,
    userRoleId: data.user_role_id,
    preferredShipmentRoleId: data.preferred_shipment_role_id,
    preferredFreightForwarderId: data.preferred_freight_forwarder_id,
    preferredAirFreightForwarderId: data.preferred_air_freight_forwarder_id,
    preferredSeaFreightForwarderId: data.preferred_sea_freight_forwarder_id,
    organizationLogo: data.organization_logo,
    can_buy: data.can_buy,
    time_zone: data.time_zone,
    user_dashboard_settings: data.user_dashboard_settings,
    organization_visibility_trial: data.organization_visibility_trial,
    tenantOrganization: data.tenant_organization,
    out_of_office_till: data.out_of_office_till,
    shipment_groups_last_synced_at: data.shipment_groups_last_synced_at,
    passwordExpirationDate: data.password_expiration_date,
    hardBounceEmail: data.hard_bounce_email,
  }
}
