import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Button, Typography, Box } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import LoadingButton from '@mui/lab/LoadingButton'
import { capitalize } from 'lodash'
import FormLabel from 'src/stories/Lab/FormLabel'

import { showNotification } from 'src/stores/actionCreators/notifications'
import Input from 'src/components/Common/Input/MuiInput'
import Modal from 'src/components/Common/Modal'
import { useTranslation } from 'react-i18next'

import {
  userGetUserData,
  userSubmitUserData,
  watchShipmentRulesToggleModal,
  watchShipmentRuleCreate,
  watchShipmentRuleUpdate,
  countriesGetCountries,
  usersGetData,
} from '../../../stores/actionCreators'

import { promisifyAction } from '../../../utils'
import { conditionOptions } from './constants'

import WatchShipmentRuleCondition from './WatchShipmentRuleCondition'

interface IProps {
  userId?: string
}

const WatchShipmentRuleModal: FunctionComponent<IProps> = ({ userId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isModalOpen, watchShipmentSetting, currentRule } = useSelector(
    (state: IGlobalState) => ({
      watchShipmentSetting: state.user.watchShipmentSetting,
      isModalOpen: state.watchShipmentRules.isModalOpen,
      currentRule: state.watchShipmentRules.currentRule,
    }),
    shallowEqual
  )

  const [formData, setFormData] = useState<IWatchShipmentRule>(currentRule)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  useEffect(() => {
    getCountriesAsync()
  }, [])

  useEffect(() => {
    setFormData(JSON.parse(JSON.stringify(currentRule)))
  }, [currentRule, isModalOpen])

  const isEdit = useMemo(() => !!currentRule.id, [currentRule])

  const updateUser = promisifyAction(dispatch, userSubmitUserData)
  const createRule = promisifyAction(dispatch, watchShipmentRuleCreate)
  const updateRule = promisifyAction(dispatch, watchShipmentRuleUpdate)
  const getCountriesAsync = promisifyAction(dispatch, countriesGetCountries)
  const toggleModal = promisifyAction(dispatch, watchShipmentRulesToggleModal)
  const callNotification = promisifyAction(dispatch, showNotification)
  const getDataAsync = promisifyAction(
    dispatch,
    userId ? usersGetData : userGetUserData
  )

  const getProperConditions = (condition: string) => {
    const selectedConditions = formData.watch_shipment_rule_conditions
      .filter((x) => !x._destroy && x.condition !== condition)
      .map((x) => x.condition)
    return conditionOptions.filter((option) => {
      const excluder = (excludingValue: string) => {
        return (
          option.id === condition ||
          !(
            selectedConditions.includes(option.id) ||
            selectedConditions.includes(excludingValue)
          )
        )
      }

      switch (option.id) {
        case 'departing_from_port':
          return excluder('departing_from_country')
        case 'departing_from_country':
          return excluder('departing_from_port')
        case 'arriving_to_port':
          return excluder('arriving_in_country')
        case 'arriving_in_country':
          return excluder('arriving_to_port')
        default:
          return (
            !selectedConditions.includes(option.id) || option.id === condition
          )
      }
    })
  }

  const onClose = () => {
    toggleModal(false)
    setButtonLoading(false)
  }

  const validate = () => {
    const errors: string[] = []
    const conditions = formData.watch_shipment_rule_conditions.filter(
      (x) => !x._destroy
    )
    if (!formData.name) {
      errors.push('group should has name')
    }
    if (!conditions.length) {
      errors.push('group should has at least one condition')
    }

    if (!conditions.every((x) => x.condition && x.value)) {
      errors.push('all conditions should be filled')
    }

    if (errors.length) {
      callNotification({
        message: capitalize(errors.join(', ')),
        severity: 'error',
      })
    }

    return !!errors.length
  }

  const onSave = async () => {
    if (validate()) return
    setButtonLoading(true)
    if (watchShipmentSetting !== 'custom') {
      await updateUser({ watch_shipment_setting: 'custom' })
    }
    try {
      if (isEdit) {
        await updateRule(formData.id, formData)
      } else {
        await createRule(formData)
      }
    } catch (error) {
      return
    }

    getDataAsync(userId)
    onClose()
    callNotification({
      message: `Shipment group was successfully ${
        isEdit ? 'updated' : 'created'
      }.`,
      severity: 'success',
    })
  }

  const onNameChange = (value: string) => {
    const newData = { ...formData }
    newData.name = value
    setFormData(newData)
  }

  const onAddConditionClick = () => {
    const newData = { ...formData }
    newData.watch_shipment_rule_conditions.push({
      condition: '',
      value: '',
      condition_direction: 'is',
      value_label: '',
    })
    setFormData(newData)
  }

  const onDeleteConditionClick = (index: number) => {
    const newData = { ...formData }
    if (newData.watch_shipment_rule_conditions[index].id) {
      newData.watch_shipment_rule_conditions[index]._destroy = true
    } else {
      newData.watch_shipment_rule_conditions.splice(index, 1)
    }
    setFormData(newData)
  }

  const onConditionChange = (
    index: number,
    condition: IWatchShipmentRuleCondition
  ) => {
    const newData = { ...formData }
    newData.watch_shipment_rule_conditions[index] = condition
    setFormData(newData)
  }

  return (
    <Modal.Window open={isModalOpen} onClose={onClose}>
      <Modal.Title
        children={
          isEdit
            ? t(
                'account.tabs.shipments.shipment_groups.modal_title.edit',
                'Edit shipment group'
              )
            : t(
                'account.tabs.shipments.shipment_groups.modal_title.add',
                'Add shipment group'
              )
        }
        onClose={onClose}
      />

      <Modal.Content>
        <Typography
          variant="body1"
          children={t(
            'account.tabs.shipments.shipment_groups.description',
            'By selecting one or multiple conditions you are able to create a group of shipments for which you will receive updates.'
          )}
        />

        <Box mt={3}>
          <FormLabel
            label={t(
              'account.tabs.shipments.shipment_groups.form.name_label',
              'Name shipment group'
            )}
          />
          <Input
            data-testid="watch-shipment-rule-name"
            placeholder={t(
              'account.tabs.shipments.shipment_groups.form.name_placeholder',
              'E.g. departing from port of'
            )}
            className="input-field"
            value={formData.name || ''}
            onChange={onNameChange}
          />
        </Box>
        {formData.watch_shipment_rule_conditions.map((ruleCondition, i) => {
          if (ruleCondition._destroy) return null

          return (
            <WatchShipmentRuleCondition
              ruleCondition={ruleCondition}
              index={i}
              key={i}
              conditionOptions={getProperConditions(ruleCondition.condition)}
              onDeleteConditionClick={onDeleteConditionClick}
              deletable={
                formData.watch_shipment_rule_conditions.filter(
                  (x) => !x._destroy
                ).length > 1
              }
              onConditionChange={onConditionChange}
            />
          )
        })}

        <Box mt={2}>
          <Button
            variant="text"
            color="primary"
            size="medium"
            data-testid="watch-rule-add"
            onClick={onAddConditionClick}
            startIcon={<AddRoundedIcon />}
          >
            {t(
              'account.tabs.shipments.shipment_groups.form.add_condition_button',
              'Add condition'
            )}
          </Button>
        </Box>
      </Modal.Content>

      <Modal.Actions>
        <Button variant="outlined" onClick={onClose}>
          {t('common.buttons.cancel', 'Cancel')}
        </Button>
        <LoadingButton
          loading={buttonLoading}
          variant="contained"
          onClick={onSave}
          size="medium"
          children={t('common.buttons.save', 'Save')}
          disabled={buttonLoading}
          id="shipment-edit-window-save-button"
          data-testid="watch-shipment-rule-save"
        />
      </Modal.Actions>
    </Modal.Window>
  )
}
export default WatchShipmentRuleModal
