import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../actions/shipmentDocs'
import * as actionCreators from '../../actionCreators/shipmentDocs'
import {
  simpleGetAndResolve,
  simplePutAndResolve,
  simpleDeleteAndResolve,
  simplePatchAndResolve,
} from '../factories'
import createShipmentDocument from './uploadShipmentDocument'

const getShipmentDocuments = simpleGetAndResolve(
  (action) => `/api/v1/shipments/${action.id}/documents`,
  actionCreators.shipmentDocumentsGetDataSuccess,
  (response) => {
    let responseData = {}

    if (response.data != null) {
      if (response.data.timestamp != null) {
        responseData = { ...responseData, timestamp: response.data.timestamp }
      }
      if (response.data.message != null) {
        responseData = { ...responseData, message: response.data.message }
      }
      if (response.data.data != null) {
        responseData = { ...responseData, ...response.data.data }
      }
    }

    return responseData
  }
)

const getDocumentTypes = simpleGetAndResolve(
  (action) => `/api/v1/common/document_types?modality=${action.modality}`,
  actionCreators.documentTypesGetDataSuccess,
  (response) => response.data.data.document_types
)

const shipmentDocumentSubmitData = simplePutAndResolve(
  (action) =>
    `/api/v1/shipments/${action.shipmentId}/shipment_documents/${action.documentId}`,
  actionCreators.shipmentDocumentSubmitDataSuccess,
  (response) => response.data.data
)

const deleteShipmentDocument = simpleDeleteAndResolve(
  (action) =>
    `/api/v1/shipments/${action.shipmentId}/shipment_documents/${action.documentId}`,
  actionCreators.deleteShipmentDocumentSuccess,
  (response, action) => action
)

const moveShipmentDocument = simplePatchAndResolve(
  (action) =>
    `/api/v1/shipments/${action.shipmentId}/shipment_documents/${action.documentId}/move`,
  actionCreators.moveShipmentDocumentSuccess,
  (response, action) => ({ data: response.data.data, action })
)

export default function* (): Iterator<any> {
  yield takeEvery(actions.SHIPMENT_DOCUMENTS_GET_DATA, getShipmentDocuments)
  yield takeEvery(actions.DOCUMENT_TYPES_GET_DATA, getDocumentTypes)
  yield takeEvery(
    actions.SUBMIT_SHIPMENT_DOCUMENT_DATA,
    shipmentDocumentSubmitData
  )
  yield takeEvery(actions.DELETE_SHIPMENT_DOCUMENT, deleteShipmentDocument)
  yield takeEvery(actions.CREATE_SHIPMENT_DOCUMENT, createShipmentDocument)
  yield takeEvery(actions.MOVE_SHIPMENT_DOCUMENT, moveShipmentDocument)
}
