import { searchParams } from 'src/components/Overview/Search/constants'
import { useTranslation } from 'react-i18next'
import {
  getModalityOptions,
  getLoadTypeOptions,
  getContainerScanOptions,
  getPickupOrDeliveryStatusOptions,
} from '../constants'
import { FilterOptionValue } from '../types'
import AutocompleteBase from './AutocompleteBase'

const AutocompleteMulti = ({ token, onDelete }) => {
  const { t } = useTranslation()
  const modalityOptions = getModalityOptions(t)
  const loadTypeOptions = getLoadTypeOptions(t)
  const containerScanOptions = getContainerScanOptions(t)
  const pickupOrDeliveryStatusOptions = getPickupOrDeliveryStatusOptions(t)

  const valuePickerOptions = {
    [searchParams.modality]: modalityOptions,
    [searchParams.loadType]: loadTypeOptions,
    [searchParams.pickupStatus]: pickupOrDeliveryStatusOptions,
    [searchParams.deliveryStatus]: pickupOrDeliveryStatusOptions,
    [searchParams.containerScan]: containerScanOptions,
  }

  const defaultOptions: FilterOptionValue[] =
    valuePickerOptions?.[token.value] ?? []

  const handleDelete = () => {
    onDelete(token.value)
  }

  const noOptionsText = 'No options'

  return (
    <AutocompleteBase
      options={defaultOptions}
      noOptionsText={noOptionsText}
      token={token}
      loading={false}
      onDelete={handleDelete}
    />
  )
}

export default AutocompleteMulti
