import { useState, FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import VisibilityIcon from '@mui/icons-material/Visibility'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, Typography, Menu, MenuItem, IconButton } from '@mui/material'
import { promisifyAction } from 'src/utils'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { useTranslation } from 'react-i18next'
import {
  userGetUserData,
  watchShipmentRulesSetCurrent,
  watchShipmentRulesToggleModal,
  watchShipmentRuleDelete,
  usersGetData,
} from '../../../stores/actionCreators'
import { conditionOptions } from './constants'

interface IProps {
  rule: IWatchShipmentRule
  deletable: boolean
  userId?: string
}

const WatchShipmentRule: FunctionComponent<IProps> = ({
  rule,
  deletable,
  userId,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const shipmentRuleDelete = promisifyAction(dispatch, watchShipmentRuleDelete)
  const getDataAsync = promisifyAction(
    dispatch,
    userId ? usersGetData : userGetUserData
  )

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onEditClick = () => {
    dispatch(watchShipmentRulesSetCurrent(rule))
    dispatch(watchShipmentRulesToggleModal(true))
    handleClose()
  }

  const onDeleteClick = async () => {
    await shipmentRuleDelete(rule.id)
    getDataAsync(userId)
    dispatch(
      showNotification({
        message: 'Shipment group was deleted.',
        severity: 'success',
      })
    )
    handleClose()
  }

  return (
    <div className="my-shipments--custom--rule">
      <div className="my-shipments--custom--rule--header">
        <VisibilityIcon className="my-shipments--custom--rule--header--icon" />
        <Typography variant="h5" ml={1} children={rule.name} />
        <Box sx={{ marginLeft: 'auto' }}>
          <div className="my-shipments--custom--rule--header--actions-menu">
            <IconButton onClick={handleClick} size="large">
              <MoreVertIcon color="action" />
            </IconButton>
            <Menu
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem onClick={onEditClick}>
                {t('common.buttons.edit', 'Edit')}
              </MenuItem>
              {deletable && (
                <MenuItem onClick={onDeleteClick}>
                  {t('common.buttons.delete', 'Delete')}
                </MenuItem>
              )}
            </Menu>
          </div>
        </Box>
      </div>
      <div className="my-shipments--custom--rule--body">
        {t(
          'account.tabs.shipments.shipment_groups.rules.watch_shipment',
          'Watch a shipment'
        )}
        {rule.watch_shipment_rule_conditions.map((rule_condition, i) => {
          const condition = conditionOptions.find(
            (x) => x.id === rule_condition.condition
          )?.label

          return (
            <Typography component="span" key={rule_condition.condition}>
              {!!i &&
                t(
                  'account.tabs.shipments.shipment_groups.form.and',
                  'and'
                )}{' '}
              if{' '}
              <Typography component="span" variant="body1Strong">
                {condition}{' '}
                {` ${rule_condition.condition_direction
                  .replace('_', ' ')
                  .toUpperCase()} `}{' '}
                {rule_condition.value_label}
              </Typography>
              <br />
            </Typography>
          )
        })}
      </div>
    </div>
  )
}

export default WatchShipmentRule
