import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/styles'

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  color: theme.palette.primary.main,
  minHeight: theme.spacing(5),
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
  '& .MuiListItemIcon-root': {
    minWidth: theme.spacing(4),
  },
  '&.with-icon, &.with-checkbox': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.light,
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  '&.Mui-disabled': {
    opacity: 1,
    color: theme.palette.text.disabled,
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.disabled,
    },
  },
  '&:hover': {
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
}))
