import { styled } from '@mui/styles'
import Box from '@mui/material/Box'
import Popper from '@mui/material/Popper'
import TextField from '@mui/material/TextField'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import InputBase, { inputBaseClasses } from '@mui/material/InputBase'
import ListSubheader from '@mui/material/ListSubheader'

const StyledAutocompletePopper = styled(Box)(({ theme }) => ({
  width: '100% !important',
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}))

const StyledPopper = styled(Popper)(({ theme }) => {
  return {
    maxWidth: 400,
    width: '100%',
    overflow: 'hidden',
    zIndex: theme.zIndex.modal,
    boxShadow: theme.shadows[8],
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
  }
})

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  width: '100%',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  '& input': {
    borderRadius: 0,
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: 'none',
    ...theme.typography.body1,
  },
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  [`& .${inputBaseClasses.root}`]: {
    paddingRight: theme.spacing(1),
    [`& .${inputBaseClasses.input}`]: {
      cursor: 'pointer',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '&.empty': {
        color: theme.palette.grey[600],
      },
    },
  },
}))

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  color: theme.palette.primary.main,
}))

export {
  StyledAutocompletePopper,
  StyledTextField,
  StyledInput,
  StyledPopper,
  StyledListSubheader,
}
