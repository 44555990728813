import { HTMLAttributes, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { delay, uniqBy } from 'lodash'
import Autocomplete, {
  AutocompleteRenderGroupParams,
  AutocompleteRenderOptionState,
  createFilterOptions,
} from '@mui/material/Autocomplete'
import HistoryIcon from '@mui/icons-material/History'
import Paper, { PaperProps } from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { useFormContext } from 'react-hook-form'
import Input from 'src/components/Overview/Search/FormElements/Input'
import { FilterOptionsState } from '@mui/material/useAutocomplete'
import { MenuItemWithIcon } from 'src/stories/Lab/Menu/components/MenuItemWithIcon'
import { FilterOptions } from 'src/components/Overview/Search/types'
import { searchFilterGroups } from '../../Search/constants'
import { getSearchToken } from '../../utils'
import RecentFiltersManager, { SavedFilterProps } from '../RecentSearches'
import CategoryAutocompleteHelperText from './CategoryAutocompleteHelperText'
import CategoryAutocompletePlaceholder from './CategoryAutocompletePlaceholder'

const filter = createFilterOptions<FilterOptions>()

let inputRef

const CategoryAutocompletePaperComponent = (props: PaperProps) => {
  return (
    <Paper
      elevation={2}
      sx={{
        width: 550,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'grey.200',
        '& .group': {
          '&:not(:first-of-type)': {
            borderTop: '1px solid',
            borderColor: 'grey.200',
            marginTop: 1,
            paddingTop: 1,
          },
        },
      }}
    >
      {props.children}
      <Box>
        <Divider />
        <CategoryAutocompleteHelperText />
      </Box>
    </Paper>
  )
}

const CategoryAutocompleteGroups = (params: AutocompleteRenderGroupParams) => {
  return (
    <Stack key={params.group} className="group" data-testid={params.group}>
      <Typography
        variant="overline"
        color="text.secondary"
        children={searchFilterGroups?.[params.group] ?? ''}
        component="div"
        sx={{ p: 2, pt: 1.5 }}
      />
      {params.children}
    </Stack>
  )
}

const CategoryAutocomplete = ({
  tokens,
  options,
  onSearchKeyDown,
  onCategoryChange,
}) => {
  const recentSearchManager = new RecentFiltersManager()
  const recentSearches: SavedFilterProps[] = recentSearchManager.getRecentFilters()
  const { t } = useTranslation()

  const recentSearchesOptions: FilterOptions[] = recentSearches.map(
    (recentSearch) => ({
      value: recentSearch.key,
      label: `${recentSearch.label}: ${recentSearch.text}`,
      component: Input,
      group: 'recent_searches',
      data: recentSearch.data,
      icon: HistoryIcon,
    })
  )
  const { watch } = useFormContext()
  const [categorySelectOpen, setCategorySelectOpen] = useState(false)
  const categoryOptions: FilterOptions[] = [
    ...options.filter(
      (option) =>
        !tokens
          .map((token: FilterOptions) => token.value)
          .includes(option.value)
    ),
    ...uniqBy(recentSearchesOptions, 'label'),
  ]

  const openAutocomplete = () => {
    setCategorySelectOpen(true)
  }

  const closeAutocomplete = () => {
    setCategorySelectOpen(false)
  }

  const getOptionEqualToValue = (option: FilterOptions, value: FilterOptions) =>
    option.value === value.value

  const getFilteredOptions = (
    options: FilterOptions[],
    params: FilterOptionsState<FilterOptions>
  ) => {
    const filtered = filter(options, params)
    const tokenValues = tokens.map((token) => token.value)
    const foundFilters = filtered.filter(
      (filteredItem) =>
        filteredItem.group === 'filters' &&
        !tokenValues.includes(filteredItem.value)
    )
    if (params.inputValue !== '' && foundFilters.length === 0) {
      filtered.unshift(getSearchToken(params.inputValue))
    }
    return filtered
  }

  const getOptionLabel = (option: FilterOptions) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option
    }
    return option.label
  }

  const getRenderedOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: FilterOptions,
    { selected }: AutocompleteRenderOptionState
  ) => {
    const { className, ...rest } = props
    return (
      <MenuItemWithIcon
        icon={option?.icon ?? undefined}
        {...rest}
        selected={selected}
        text={option.label}
      />
    )
  }

  const setInputFocus = () => {
    if (inputRef?.current) {
      inputRef.current.focus()
    }
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.code === 'Backspace' &&
      tokens.length > 0 &&
      event.currentTarget.value === ''
    ) {
      closeAutocomplete()
    }
    onSearchKeyDown(event)
  }

  useEffect(() => {
    const subscription = watch((value) => {
      if (value?.open === false) {
        delay(() => {
          setInputFocus()
        }, 200)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const getRenderedInput = (params) => {
    inputRef = params.inputProps.ref
    return (
      <Box
        ref={params.InputProps.ref}
        sx={{ position: 'relative', minWidth: 340 }}
        onClick={openAutocomplete}
      >
        {params.inputProps.value === '' && !categorySelectOpen && (
          <CategoryAutocompletePlaceholder />
        )}
        <input
          autoFocus={true}
          type="text"
          onKeyDown={onKeyDown}
          {...params.inputProps}
          style={{ height: '32px' }}
          data-testid="overview-search-input"
          placeholder={
            categorySelectOpen ? t('vessels_page.placeholder_autocomplete') : ''
          }
        />
      </Box>
    )
  }

  return (
    <Autocomplete
      fullWidth
      autoHighlight
      open={categorySelectOpen}
      onChange={onCategoryChange}
      options={categoryOptions}
      onClose={closeAutocomplete}
      onOpen={openAutocomplete}
      isOptionEqualToValue={getOptionEqualToValue}
      value={null}
      blurOnSelect={true}
      filterOptions={getFilteredOptions}
      getOptionLabel={getOptionLabel}
      renderOption={getRenderedOption}
      renderInput={getRenderedInput}
      PaperComponent={CategoryAutocompletePaperComponent}
      groupBy={(option) => option.group}
      ListboxProps={{ style: { maxHeight: '64vh' } }}
      renderGroup={(params) => {
        return <CategoryAutocompleteGroups {...params} />
      }}
    />
  )
}

export default CategoryAutocomplete
