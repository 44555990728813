import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { Link as MuiLink } from '@mui/material'
import { Link } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import LockIcon from '@mui/icons-material/Lock'
import 'src/components/FilterOrganizationIndicator'
import { getLocalStorage } from '../Common/Table/DataTable/TableWrapper.utils'

const passwordExpirationNoticeDays = 30

const PasswordExpirationNotice = () => {
  const { t } = useTranslation()
  const organization = getLocalStorage('scopedOrganization', null)
  const { passwordExpirationDate, hardBounceEmail } = useSelector(
    (state: IGlobalState) => {
      return {
        passwordExpirationDate: state.user.passwordExpirationDate,
        hardBounceEmail: state.user.hardBounceEmail,
      }
    },
    shallowEqual
  )

  const showBanner = useMemo(() => {
    const zone = DateTime.local().zoneName
    const expirationDate = DateTime.fromISO(passwordExpirationDate).setZone(
      zone
    )

    return (
      !organization &&
      !hardBounceEmail &&
      expirationDate.diffNow('days').days <= passwordExpirationNoticeDays
    )
  }, [passwordExpirationDate])

  if (!showBanner) {
    return null
  }

  return (
    <div className="impersonation-block">
      <div className="impersonation-block--impersonator">
        <div className="icon-block">
          <LockIcon />
        </div>
        <div>{t('password_expiration_alert.text')}</div>

        <MuiLink
          variant="body1"
          component={Link}
          sx={{ color: 'warning.dark' }}
          to="/account/my_shipments?action=reset_password"
        >
          {t('password_expiration_alert.action')}
        </MuiLink>
      </div>
    </div>
  )
}

export default PasswordExpirationNotice
