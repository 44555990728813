import {
  MRT_DensityState,
  MRT_VisibilityState,
  MRT_ColumnOrderState,
  MRT_ColumnPinningState,
  MRT_ColumnSizingState,
  MRT_SortingState,
} from 'material-react-table'
import { TableStorageKeysProps } from './TableWrapper.props'

const storageKeyList = [
  'density',
  'columnOrder',
  'columnSizing',
  'columnPinning',
  'columnSorting',
  'columnVisibility',
]

export const setLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const getStorageKeys = (tableStorageKey: string) => {
  return storageKeyList.reduce((acc: TableStorageKeysProps, key: string) => {
    acc[key] = `${tableStorageKey}_${key}`
    return acc
  }, {} as TableStorageKeysProps)
}

export const getLocalStorage = (key: string, defaultValue) => {
  const storedValue = localStorage.getItem(key)
  if (storedValue) {
    return JSON.parse(storedValue)
  }
  return defaultValue
}

export const getTableStorage = (tableStorageKey: string) => {
  const storageKeys = getStorageKeys(tableStorageKey)

  const storedDensity: MRT_DensityState = getLocalStorage(
    storageKeys['density'],
    'compact'
  )

  const storedColumnOrder: MRT_ColumnOrderState = getLocalStorage(
    storageKeys['columnOrder'],
    []
  )

  const storedColumnVisibility: MRT_VisibilityState = getLocalStorage(
    storageKeys['columnVisibility'],
    {}
  )

  const storedColumnSizing: MRT_ColumnSizingState = getLocalStorage(
    storageKeys['columnSizing'],
    {}
  )

  const storedColumnPinning: MRT_ColumnPinningState = getLocalStorage(
    storageKeys['columnPinning'],
    {}
  )

  const storedColumnSorting: MRT_SortingState = getLocalStorage(
    storageKeys['columnSorting'],
    []
  )

  return {
    storedDensity,
    storedColumnOrder,
    storedColumnVisibility,
    storedColumnSizing,
    storedColumnPinning,
    storedColumnSorting,
  }
}
