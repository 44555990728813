import { TFunction } from 'i18next'
import { LoadTypeEnum, ModalityEnum } from 'src/config/constants'
import { FilterOptionValue } from './types'

export const searchParams = {
  search: 'search',
  pickupTime: 'pickup_time',
  deliveryTime: 'delivery_time',
  pol: 'pol_ids',
  pod: 'pod_ids',
  departure: 'departure_date',
  arrival: 'arrival_date',
  modality: 'modality',
  loadType: 'load_type',
  shipmentStatus: 'status_ids',
  pickupStatus: 'pickup_status',
  deliveryStatus: 'delivery_status',
  consignee: 'consignee_names',
  shipper: 'shipper_names',
  containerScan: 'inspection_statuses',
}

export const getModalityOptions = (
  t: TFunction<'translation', undefined>
): FilterOptionValue[] => [
  { value: ModalityEnum.Sea, label: t('common.modality.sea', 'Sea') },
  { value: ModalityEnum.Air, label: t('common.modality.air', 'Air') },
  { value: ModalityEnum.Road, label: t('common.modality.road', 'Road') },
  { value: ModalityEnum.Rail, label: t('common.modality.rail', 'Rail') },
  { value: ModalityEnum.Barge, label: t('common.modality.barge', 'Barge') },
]

export const getLoadTypeOptions = (
  t: TFunction<'translation', undefined>
): FilterOptionValue[] => [
  { value: LoadTypeEnum.fcl, label: t('common.fcl', 'FCL') },
  { value: LoadTypeEnum.lcl, label: t('common.lcl', 'LCL') },
]

export const getContainerScanOptions = (
  t: TFunction<'translation', undefined>
): FilterOptionValue[] => [
  {
    value: 'none',
    label: t('common.container_scan_options.none', 'No status'),
  },
  {
    value: 'released',
    label: t('common.container_scan_options.released', 'Released'),
  },
  {
    value: 'notified',
    label: t('common.container_scan_options.notified', 'Selected'),
  },
]

export const getPickupOrDeliveryStatusOptions = (
  t: TFunction<'translation', undefined>
): FilterOptionValue[] => [
  {
    value: 'estimated',
    label: t(
      'common.pickup_and_delivery_status_options.estimated',
      'Estimated'
    ),
  },
  {
    value: 'requested',
    label: t(
      'common.pickup_and_delivery_status_options.requested',
      'Requested'
    ),
  },
  {
    value: 'proposed',
    label: t('common.pickup_and_delivery_status_options.proposed', 'Proposed'),
  },
  {
    value: 'confirmed',
    label: t(
      'common.pickup_and_delivery_status_options.confirmed',
      'Confirmed'
    ),
  },
  {
    value: 'completed',
    label: t(
      'common.pickup_and_delivery_status_options.completed',
      'Completed'
    ),
  },
]

export const pickupOrDeliveryStatusColors = {
  requested: 'warning',
  estimated: 'primary',
  proposed: 'secondary',
  confirmed: 'success',
  completed: 'success',
}

export const overviewFilterItemsStorageKey = 'overviewStateFilterItems'

export const datePickerDefaultValue = {
  start: null,
  end: null,
}

export const savedFilterKey = 'overviewFilters'
export const savedFilterParamsKey = 'overviewFiltersParams'

export const searchFilterGroups = {
  recent_searches: 'Recent searches/filters',
  filters: 'Filters',
}

export const customLabelsForWeekOptions = [
  {
    i18nKey: 'current_week',
    defaultValue: 'Current week (week {{weekNumber}})',
  },
  {
    i18nKey: 'next_week',
    defaultValue: 'Next week (week {{weekNumber}})',
  },
  {
    i18nKey: 'week_with_number',
    defaultValue: 'Week {{weekNumber}}',
  },
]
