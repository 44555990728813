import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useTranslation } from 'react-i18next'

const OverviewViewsDelete = ({ open, onClose, onDelete }) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {t('overview_views.delete_view.title', 'Delete view')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            'overview_views.delete_view.description',
            'Are you sure you want to delete this view?'
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={onClose}>
          {t('common.buttons.cancel', 'Cancel')}
        </Button>
        <Button size="large" variant="contained" onClick={onDelete}>
          {t('overview_views.delete_view.proceed', 'Proceed')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OverviewViewsDelete
