import { FunctionComponent, memo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  includes,
  groupBy,
  orderBy,
  keys,
  isEmpty,
  uniqBy,
  Dictionary,
  filter,
  uniq,
} from 'lodash'
import Tooltip from '@mui/material/Tooltip'
import { AvatarGroup } from 'src/stories/AvatarGroup'
import { Typography, ButtonBase, Box, Link, styled } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import {
  containerTypeDescription,
  convertDateToUniversalFormat,
} from '../../utils/helpers'
import { permissionTo } from '../../utils'
import ShipmentMainInfo from './ShipmentMainInfo'

import './styles.scss'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  backgroundColor: 'var(--grey-200)',
}))

const getActiveStep = (percent: number) => {
  switch (true) {
    case percent >= 99:
      return 4
    case percent >= 66:
      return 3
    case percent >= 33:
      return 2
    case percent >= 10:
      return 1
    default:
      return 0
  }
}

const StepperPartyLabel = ({ empty, items }) => {
  const { t } = useTranslation()

  if (items.length === 0)
    return (
      <Typography variant="body1" noWrap>
        {empty}
      </Typography>
    )
  if (items.length > 3) {
    const visiblePart = items.slice(0, 3)
    return (
      <>
        {visiblePart.map((part) => (
          <Typography key={part.label} variant="body1" noWrap>
            {part}
          </Typography>
        ))}
        <Tooltip
          followCursor
          title={
            <ul style={{ padding: 0, margin: 0 }}>
              {items.map((part) => (
                <li key={part.label}>
                  <Typography key={part.label} variant="body1">
                    {part}
                  </Typography>
                </li>
              ))}
            </ul>
          }
        >
          <Typography variant="body1" noWrap>
            <Link href="#">
              {t('shipments.list.parties_list.see_all', 'See all ({{size}})', {
                size: items.length,
              })}
            </Link>
          </Typography>
        </Tooltip>
      </>
    )
  }
  return items.map((item) => (
    <Typography key={item} variant="body1" noWrap>
      {item}
    </Typography>
  ))
}

const steps = (t) => [
  {
    label: t('common.shipper', 'Shipper'),
    component: (shipment) => (
      <StepperPartyLabel
        empty={t(
          'shipments.list.parties_list.shipper_not_set',
          'Shipper not set.'
        )}
        items={uniq(
          filter(shipment.bookings, 'shipper_name').map(
            (booking) => booking.shipper_name
          )
        )}
      />
    ),
  },
  {
    label: t('common.etd', 'ETD'),
    component: (shipment) => (
      <Typography variant="body1" noWrap>
        <Typography variant="body1Strong" mr={1} noWrap>
          {shipment.estimated_departure
            ? convertDateToUniversalFormat(shipment.estimated_departure)
            : t('common.tba', 'TBA')}
        </Typography>
        {shipment.loading_port}
      </Typography>
    ),
  },
  {
    label: t('common.eta', 'ETA'),
    component: (shipment) => (
      <Typography variant="body1" noWrap>
        <Typography variant="body1Strong" mr={1} noWrap>
          {shipment.estimated_arrival
            ? convertDateToUniversalFormat(shipment.estimated_arrival)
            : t('common.tba', 'TBA')}
        </Typography>
        {shipment.discharge_port}
      </Typography>
    ),
  },
  {
    label: t('common.consignee', 'Consignee'),
    component: (shipment) => (
      <StepperPartyLabel
        empty={t(
          'shipments.list.parties_list.consignee_not_set',
          'Consignee not set.'
        )}
        items={uniq(
          filter(shipment.bookings, 'consignee_name').map(
            (booking) => booking.consignee_name
          )
        )}
      />
    ),
  },
]

const ShipmentProgress = ({ value, children }) => {
  const { t } = useTranslation()

  const activeStep = getActiveStep(value)

  const getProgress = (currentBar, stepIndex) => {
    if (value >= currentBar) return 100
    if (stepIndex !== activeStep) return 0
    if (value < currentBar) return value
  }

  const translatedSteps = steps(t)

  return (
    <Box display="flex" minWidth={0}>
      {translatedSteps.map((step, index) => {
        const isLast = index === translatedSteps.length - 1
        const isActive = index + 1 <= activeStep
        const oneConnectorPercent = Math.floor(
          100 / (translatedSteps.length - 1)
        )
        const currentProgressBar = (index + 1) * oneConnectorPercent

        return (
          <Box
            key={step.label}
            sx={{
              flex: 1,
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                width: '16px',
                height: '16px',
                borderRadius: '16px',
                zIndex: 1,
                backgroundColor: isActive
                  ? 'var(--primary-900)'
                  : 'var(--grey-200)',
                position: 'absolute',
              }}
            />
            {!isLast && (
              <BorderLinearProgress
                sx={{
                  marginTop: '6.5px',
                }}
                variant="determinate"
                value={getProgress(currentProgressBar, index + 1)}
              />
            )}
            <Box
              sx={{
                borderColor: isActive
                  ? 'var(--primary-900)'
                  : 'var(--grey-200)',
                padding: isLast ? '23px 8px 0 0' : '14px 8px 0 0',
                marginTop: '6.5px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Typography noWrap variant="body1Strong">
                {step.label}
              </Typography>
              {children(step.component)}
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

interface IShipmentProps {
  shipment: IDetailedShipment
}

const Shipment: FunctionComponent<IShipmentProps> = memo(({ shipment }) => {
  const history = useHistory()

  const { user } = useSelector(
    (state: IGlobalState) => ({
      user: state.user,
    }),
    shallowEqual
  )

  if (shipment == null || isEmpty(shipment)) {
    return null
  }

  const hideVessel =
    !!shipment && includes(shipment.status_groups, 'sailing_to_be_announced')

  const renderContainers = () => {
    const shipmentType: string = shipment ? shipment.shipment_type : ''
    const containerType: string =
      shipment && shipment.container_type ? shipment.container_type : ''
    const containers: IContainer[] = shipment ? shipment.containers : []
    const groupContainers: Dictionary<IContainerIdentifier[]> | never[] =
      groupBy(containers, 'container_type.name') || {}
    const containerNames: string[] = orderBy(keys(groupContainers)) || []

    return (
      <span className="shipment__type-desc">
        {containerNames.map((name: string) => (
          <span key={name}>
            {groupContainers[name].length +
              ' x ' +
              containerTypeDescription(
                shipmentType,
                containerType || shipmentType === 'rail' ? name : null
              )}
          </span>
        ))}
      </span>
    )
  }

  const collaborators = uniqBy(
    shipment.collaborators,
    (collaborator) => collaborator.organization_id
  ).filter((collaborator) => {
    if (shipment.visibility_only) {
      if (
        collaborator.roles.length === 1 &&
        collaborator.roles[0].role === 'Visibility provider'
      ) {
        return false
      }
    }
    return collaborator.organization_id !== user.organizationId
  })

  const goToShipment = (event) => {
    const elementClassList = event.target.classList
    if (elementClassList.contains('blocked-open')) {
      return
    }
    if (event.ctrlKey || event.metaKey) {
      window.open(`/shipments/${shipment.id}`)
    } else {
      history.push({
        pathname: `/shipments/${shipment.id}`,
      })
    }
  }

  return (
    <article className="shipment" data-testid="shipment-row">
      <ButtonBase
        sx={{ width: '100%' }}
        component={Box}
        data-testid="shipment-order-row"
        onClick={goToShipment}
      >
        <Box className="shipment__block">
          <ShipmentMainInfo shipment={shipment} />
          <ShipmentProgress value={shipment.progress_percent}>
            {(component) => component(shipment)}
          </ShipmentProgress>
          <Box className="shipment__sub-main">
            <header className="shipment__sub-title">
              <Typography className="medium normal" children={shipment.title} />
            </header>
            <div className="shipment__line shipment__line_sub-amount">
              <span className="shipment__logo">
                <Typography className="small light" component={'div'}>
                  {!hideVessel && (
                    <>
                      <div>{shipment.carrier_name}</div>
                      <div>
                        {[shipment.vessel, shipment.voyage_no]
                          .filter(Boolean)
                          .join(' | ')}
                      </div>
                    </>
                  )}
                </Typography>
              </span>
              <Typography className="small light">
                {renderContainers()}
              </Typography>
            </div>
          </Box>
          <Box className="shipment__indicators">
            {permissionTo('shipments.shipment_collaborators.view') && (
              <Box className="shipment__collaborators">
                <AvatarGroup
                  size="small"
                  avatars={collaborators.map((collaborator: ICollaborator) => ({
                    id: collaborator.organization_id.toString(),
                    alt: collaborator.name,
                    className: collaborator.organization_role_code,
                    src: collaborator.logo || '',
                  }))}
                  max={3}
                />
              </Box>
            )}
          </Box>
        </Box>
      </ButtonBase>
    </article>
  )
})

export default Shipment
