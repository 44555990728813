import { useTranslation } from 'react-i18next'
import { useSelector, shallowEqual } from 'react-redux'
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe'
import 'src/components/FilterOrganizationIndicator'
import { getLocalStorage } from '../Common/Table/DataTable/TableWrapper.utils'

const HardBouncedEmailNotice = () => {
  const { t } = useTranslation()
  const organization = getLocalStorage('scopedOrganization', null)
  const { hardBounceEmail } = useSelector((state: IGlobalState) => {
    return {
      hardBounceEmail: state.user.hardBounceEmail,
    }
  }, shallowEqual)

  if (organization || !hardBounceEmail) {
    return null
  }

  return (
    <div className="impersonation-block">
      <div className="impersonation-block--impersonator">
        <div className="icon-block">
          <UnsubscribeIcon />
        </div>
        <div>
          {t(
            'hard_bounced_email_alert.text',
            'We tried to deliver emails to this address without success. Please verify your email address under /account or contact a Shypple employee. '
          )}
        </div>
        <div />
      </div>
    </div>
  )
}

export default HardBouncedEmailNotice
