import uuidv4 from 'uuid/v4'
import { toNumber } from 'lodash'
import * as tabRoutes from './tabRoutes'

interface ILayoutTabProps {
  label: any
  value: string
  badgeFieldName?: string
  permission: string
  extendedPermission: string
  badgeFieldPermission?: string
}

export const tabClasses = {
  root: 'shipment-layout__tab mui-override',
  wrapper: 'shipment-layout__tab-text-wrapper mui-override',
  selected: 'shipment-layout__tab_selected mui-override',
}

export const matchDummy = {
  params: {
    id: 'none',
  },
  url: '',
}

export const tabMap = (t): ILayoutTabProps[] => [
  {
    label: t('shipments.tabs.tasks', 'Tasks'),
    value: tabRoutes.tasks,
    permission: 'shipments.tasks.view',
    extendedPermission: '',
  },
  {
    label: t('shipments.tabs.bookings', 'Bookings'),
    value: tabRoutes.bookings,
    permission: 'shipments.bookings.view',
    extendedPermission: '',
  },
  {
    label: t('shipments.tabs.containers', 'Containers'),
    value: tabRoutes.containers,
    permission: '',
    extendedPermission: '',
  },
  {
    label: t('shipments.tabs.documents', 'Documents'),
    value: tabRoutes.documentation,
    badgeFieldName: '',
    permission: 'shipments.documents.view',
    extendedPermission: '',
  },
  {
    label: t('shipments.tabs.chats', 'Chats'),
    value: tabRoutes.chats,
    badgeFieldName: 'chatCounter',
    permission: 'chats.view',
    extendedPermission: '',
  },
  {
    label: t('shipments.tabs.costs', 'Costs'),
    value: tabRoutes.costs,
    permission: 'shipments.costs.all',
    extendedPermission: '',
  },
  {
    label: t('purchase_orders.shipment.tabs.title', 'Order management'),
    value: tabRoutes.purchaseOrders,
    badgeFieldName: '',
    permission: 'shipment.purchase_orders.view',
    extendedPermission: '',
  },
]

export const initialState = {
  counters: {
    documents: 0,
    instructions: 0,
    chatCounter: 0,
  },
  busy: true,
  dialogIsOpen: false,
}

export const LOAD_TYPES: string[] = ['lcl', 'air']

export const INITIAL_CARGO_VALUE = {
  id: toNumber(uuidv4()),
  length_mm: 0,
  width_mm: 0,
  height_mm: 0,
  volume_cbm: 1,
  total_weight_kg: '',
  total: 1,
  quantity: 1,
  package_type_id: '9',
  total_volume_cbm: '',
  goods_description: '',
  stackable: false,
  marks_and_numbers: null,
  total_volume_weight: null,
  total_chargeable_weight: '',
  package_type: { id: 9, code: 'PK', name: 'Package' },
}

export interface ChipStatus {
  label: string
  color: 'default' | 'primary' | 'warning' | 'success' | 'error'
  text: string
}

export const moveTypes = [
  { id: 'port_to_port', label: 'Port to port' },
  { id: 'door_to_port', label: 'Door to port' },
  { id: 'port_to_door', label: 'Port to door' },
  { id: 'door_to_door', label: 'Door to door' },
]

export const carrierBookingStatuses: ChipStatus[] = [
  {
    label: 'create carrier booking',
    color: 'default',
    text:
      'No carrier booking requested. Click the Request carrier booking button to start the process.',
  },
  {
    label: 'created',
    color: 'primary',
    text:
      'Review and complete your carrier booking before sending it to the carrier. Click the Edit carrier booking button to continue the process.',
  },
  {
    label: 'pending',
    color: 'warning',
    text:
      'Your carrier booking has been sent to the carrier. You will receive an email confirmation and the carrier will process your request.',
  },
  {
    label: 'confirm',
    color: 'success',
    text: 'The carrier has confirmed your booking. ',
  },
  {
    label: 'request',
    color: 'default',
    text:
      'Your request was successful. We will let you know once the carrier has updated the status of your request. ',
  },
  {
    label: 'amend',
    color: 'warning',
    text: 'Your carrier booking request has been amended. ',
  },
  {
    label: 'cancel',
    color: 'default',
    text: 'Your carrier booking request has been cancelled.',
  },
  {
    label: 'replace',
    color: 'primary',
    text: 'The carrier has replaced your booking that was confirmed earlier.  ',
  },
  {
    label: 'error',
    color: 'error',
    text:
      'Your carrier booking has been unsuccessful due to incorrect information. Click the Edit carrier booking button to update the information.',
  },
  {
    label: 'declined',
    color: 'error',
    text: 'The carrier has declined your booking.',
  },
  {
    label: 'requested',
    color: 'default',
    text:
      'Your request was successful. We will let you know once the carrier has updated the status of your request.',
  },
]
