import { FunctionComponent, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import usePreAlert from 'src/hooks/usePreAlert'
import {
  Link as MuiLink,
  Tooltip,
  Popper,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  Box,
  IconButton,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { orderBy } from 'lodash'
import { AvatarGroup } from 'src/stories/AvatarGroup'
import { Avatar } from 'src/stories/Avatar'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DescriptionIcon from '@mui/icons-material/Description'
import { permissionTo } from 'src/utils'
import { toggleShipmentShareModal } from '../../stores/actionCreators'

import './styles.scss'

interface IProps {
  document: IShipmentDocument
  shipmentId: number | string
  downloadable?: boolean
  withBooking?: boolean
  containersCount?: number
  opened: boolean
  remove: () => void
  edit: () => void
  disabled: boolean
  shipmentRoles: IRoleShort[]
  isModalityAir?: boolean
  showPublicLink: boolean
}

const ShipmentDocumentHeader: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const { isModalityAir = false } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const [
    moreActionsAnchorEl,
    setMoreActionsAnchorEl,
  ] = useState<null | HTMLElement>(null)
  const dispatch = useDispatch()
  const isPreAlert = usePreAlert()

  const moreActionsOpen = Boolean(moreActionsAnchorEl)

  const handleMoreActionsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setMoreActionsAnchorEl(event.currentTarget)
  }

  const handleMoreActionsClose = () => {
    setMoreActionsAnchorEl(null)
  }

  const onMouseEnter = (event): void => {
    setAnchorEl(event.currentTarget)
  }

  const onMouseLeave = (): void => setAnchorEl(null)

  const containersCell = useMemo(() => {
    return props.document.containers.length ? (
      props.document.containers.length === props.containersCount ? (
        <MuiLink
          component={Link}
          variant="body1"
          to={`/shipments/${props.shipmentId}/containers/`}
        >
          {t('shipment_documents.table.all_containers', 'All containers')}
        </MuiLink>
      ) : (
        props.document.containers.map(
          (container: IContainerShort, index: number) => {
            return (
              <MuiLink
                component={Link}
                variant="body1"
                key={index}
                to={`/shipments/${props.shipmentId}/containers/#${container.id}`}
              >
                <>
                  {container.number || container.container_index}
                  {index !== props.document.containers.length - 1
                    ? `${', '}`
                    : ''}
                </>
              </MuiLink>
            )
          }
        )
      )
    ) : (
      '-'
    )
  }, [props.document.containers, props.containersCount])

  const renderViewable = () => {
    const documentOwner = props.document.uploaded_by
    const avatarsGroup = orderBy(props.document.viewable_by || [], [
      'name',
    ]).map((org: IOrganization) => {
      return {
        id: org.id.toString(),
        alt: org.name,
        className: org.role_code,
        src: org.logo || '',
      }
    })

    return (
      <>
        <Popper
          open={!!anchorEl}
          id={`${props.document.id}`}
          anchorEl={anchorEl}
          placement="left-start"
          className="shipment-collaborators__popper mui-override"
          style={{ background: 'white' }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="short"></TableCell>
                <TableCell className="wide">
                  {t('shipment_documents.table.organization', 'Organization')}
                </TableCell>
                <TableCell className="medium">
                  {t('shipment_documents.table.uploaded_by', 'Uploaded by')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.document.viewable_by.map((organization) => (
                <TableRow key={organization.id}>
                  <TableCell className="short">
                    <Avatar
                      size="medium"
                      alt={organization.name}
                      src={organization.logo || 'no-logo'}
                      className={`organization-logo ${organization.role_code}`}
                    />
                  </TableCell>
                  <TableCell className="medium">{organization.name}</TableCell>
                  <TableCell className="medium">
                    {organization.name === documentOwner?.organization_name
                      ? `${documentOwner.full_name}`
                      : ''}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Popper>
        <AvatarGroup
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          size="medium"
          disableTooltips={true}
          avatars={avatarsGroup}
          max={3}
        />
      </>
    )
  }

  const documentTypes = useMemo(
    () =>
      props.document.types
        .map((x) => t(`document_types.types.${x.code}`, x.name))
        .join(', '),
    [props.document.types, t]
  )

  const editDocument = () => {
    props.edit()
    handleMoreActionsClose()
  }

  const removeDocument = () => {
    props.remove()
    handleMoreActionsClose()
  }

  const shareDocument = () => {
    dispatch(toggleShipmentShareModal(true, props.document.id))
    handleMoreActionsClose()
  }

  return (
    <TableRow
      key={props.document.id}
      data-testid="shipment-documents-list-item"
    >
      <TableCell
        className="left-aligned s-wide"
        data-testid="shipment-documents-list-item-file-name"
      >
        <div className="shipment-document--file-name">
          <DescriptionIcon />
          {props.downloadable ? (
            <MuiLink
              variant="body1"
              href={props.document.download_url}
              download={props.document.original_filename}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.document.original_filename}
            </MuiLink>
          ) : (
            props.document.original_filename
          )}
        </div>
      </TableCell>
      <TableCell
        className="left-aligned s-medium"
        data-testid="shipment-documents-list-item-types"
      >
        <Tooltip title={documentTypes} placement="top">
          <div className="text-wrapper paragraph__medium ">{documentTypes}</div>
        </Tooltip>
      </TableCell>
      {!isModalityAir && (
        <TableCell
          className="text-wrapper s-medium left-aligned"
          data-testid="shipment-documents-list-item-containers"
        >
          {containersCell}
        </TableCell>
      )}
      {props.withBooking && (
        <TableCell className="left-aligned s-medium">
          {props.document.booking ? (
            <MuiLink
              component={Link}
              variant="body1"
              to={`/shipments/${props.shipmentId}/bookings/#${props.document.booking.id}`}
            >
              {props.document.booking.shipper_name ||
                props.document.booking.booking_index}
            </MuiLink>
          ) : (
            '-'
          )}
        </TableCell>
      )}
      <TableCell className="left-aligned s-medium">
        {t('common.date_medium', {
          date: props.document.created_at,
          defaultValue: '{{date, DATE_MED}}',
        })}
      </TableCell>
      <TableCell className="s-medium">{renderViewable()}</TableCell>
      <TableCell className="right-aligned short">
        <Box className="shipment-document--actions-menu">
          <IconButton
            id="more-actions-toggler"
            data-testid="shipment-documents-list-item-actions"
            aria-haspopup="true"
            aria-expanded={moreActionsOpen ? 'true' : undefined}
            aria-controls={moreActionsOpen ? 'more-actions-menu' : undefined}
            onClick={handleMoreActionsClick}
            disabled={!props.document.document_manageable}
            size="large"
          >
            <MoreVertIcon color="action" />
          </IconButton>
          <Menu
            id="more-actions-menu"
            anchorEl={moreActionsAnchorEl}
            open={moreActionsOpen}
            MenuListProps={{
              'aria-labelledby': 'more-actions-toggler',
            }}
            onClose={handleMoreActionsClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem
              key="edit"
              onClick={editDocument}
              disabled={!permissionTo('shipments.bookings.documents.manage')}
            >
              {t('common.buttons.edit', 'Edit')}
            </MenuItem>
            <MenuItem
              key="remove"
              onClick={removeDocument}
              disabled={!permissionTo('shipments.bookings.documents.manage')}
            >
              {t('common.buttons.remove', 'Remove')}
            </MenuItem>
            {isPreAlert && props.showPublicLink && (
              <MenuItem
                key="share"
                onClick={shareDocument}
                disabled={!permissionTo('shipments.bookings.documents.manage')}
              >
                {t('common.buttons.share', 'Share')}
              </MenuItem>
            )}
          </Menu>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default ShipmentDocumentHeader
