import { AutocompleteChangeDetails } from '@mui/material'
import { uniqBy } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AutocompleteOptionProps,
  AutocompleteProps,
} from './Autocomplete.props'
import AutocompleteCommon from './AutocompleteCommon'

const AutocompleteCommonRecentOptionsWrapper = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & {
    storeKey: string
  }
) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState<AutocompleteOptionProps[]>([])

  const getNewOptions = (recentOptions: AutocompleteOptionProps[]) => {
    return [
      ...recentOptions.map((option) => ({
        ...option,
        group: 'components.autocomplete.recent_options',
      })),
      ...props.options,
    ]
  }

  useEffect(() => {
    const recentOptions = JSON.parse(
      localStorage.getItem(props.storeKey) || '[]'
    )
    const newOptions = getNewOptions(recentOptions).map((option) => ({
      ...option,
      id: String(option.id),
      label: option.translationKey
        ? t(option.translationKey, option.label)
        : option.label,
    }))

    setOptions(newOptions)
  }, [props.options])

  const onChange = (
    value: AutocompleteOptionProps[],
    details?: AutocompleteChangeDetails<AutocompleteOptionProps>
  ) => {
    if (
      details?.option &&
      details?.option.group !== 'components.autocomplete.recent_options' &&
      value.find((option) => option.id === details?.option.id)
    ) {
      const recentOptions = JSON.parse(
        localStorage.getItem(props.storeKey) || '[]'
      )

      const newRecentOptions = uniqBy(
        [details.option, ...recentOptions],
        'id'
      ).slice(0, 4)

      localStorage.setItem(props.storeKey, JSON.stringify(newRecentOptions))

      const newOptions = getNewOptions(newRecentOptions)
      setOptions(newOptions)
    }

    props.onChange(value)
  }

  return <AutocompleteCommon {...props} onChange={onChange} options={options} />
}

export default AutocompleteCommonRecentOptionsWrapper
