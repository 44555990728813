import { FunctionComponent } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { Box, Button } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { useTranslation } from 'react-i18next'
import {
  watchShipmentRulesToggleModal,
  watchShipmentRulesSetCurrent,
} from '../../../stores/actionCreators'

import EmptyState from './EmptyState'
import WatchShipmentRule from './WatchShipmentRule'
import WatchShipmentRuleModal from './WatchShipmentRuleModal'

interface IProps {
  userId?: string
}

const CustomGroupOfShipments: FunctionComponent<IProps> = ({ userId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { watchShipmentRules, currentUserId } = useSelector(
    (state: IGlobalState) => ({
      watchShipmentRules: (userId ? state.users.currentUser : state.user)
        ?.watchShipmentRules,
      currentUserId: userId || state.user.id,
    }),
    shallowEqual
  )

  const onAddRuleClick = () => {
    dispatch(
      watchShipmentRulesSetCurrent({
        name: '',
        user_id: Number(currentUserId),
        watch_shipment_rule_conditions: [
          {
            condition: '',
            value: '',
            value_label: '',
            condition_direction: 'is',
          },
        ],
      })
    )
    dispatch(watchShipmentRulesToggleModal(true))
  }

  return (
    <Box className="my-shipments--custom" data-testid="my-shipments--custom">
      {watchShipmentRules.length ? (
        watchShipmentRules.map((x) => (
          <WatchShipmentRule
            rule={x}
            key={x.id}
            deletable={watchShipmentRules.length > 1}
            userId={userId}
          />
        ))
      ) : (
        <EmptyState onAddShipmentGroup={onAddRuleClick} />
      )}
      {!!watchShipmentRules.length && (
        <Button
          variant="outlined"
          size="large"
          onClick={onAddRuleClick}
          startIcon={<AddRoundedIcon />}
        >
          {t(
            'account.tabs.shipments.shipment_groups.add_shipment_group',
            'Add shipment group'
          )}
        </Button>
      )}

      <WatchShipmentRuleModal userId={userId} />
    </Box>
  )
}

export default CustomGroupOfShipments
