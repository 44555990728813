import { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import { showNotification } from 'src/stores/actionCreators/notifications'
import { promisifyAction } from 'src/utils'
import { userUpdatePassword } from '../../stores/actionCreators'
import PasswordModal from './PasswordModal'

interface IProps {
  handleClose: () => void
}

const PasswordModalContainer: FunctionComponent<IProps> = ({ handleClose }) => {
  const dispatch = useDispatch()
  const updatePassword = promisifyAction(dispatch, userUpdatePassword)

  const onSave = async ({
    password,
    current,
  }: {
    password: string
    current: string
  }) => {
    try {
      await updatePassword({ password, current_password: current })
      dispatch(
        showNotification({ message: 'Password updated', severity: 'success' })
      )
      handleClose()
    } catch (error) {
      dispatch(
        showNotification({
          message: 'Password is not updated',
          severity: 'error',
        })
      )
      throw error
    }
  }

  return <PasswordModal onSave={onSave} handleClose={handleClose} />
}
export default PasswordModalContainer
