import DescriptionIcon from '@mui/icons-material/Description'
import Link from '@mui/material/Link'
import { convertDateToLocalDateTimeWithoutYear } from 'src/utils/helpers'
import { Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import DocumentsTableCellViewableBy from './DocumentsTableCellViewableBy'

export const getTableColumn = (t) => {
  return [
    {
      accessor: 'name',
      accessorKey: 'name',
      header: t('documents.table.columns.name', 'Name'),
      minSize: 100,
      Cell: ({ row }) => (
        <>
          <Link
            href={row.original.download_url}
            target="_blank"
            data-testid="document-name"
          >
            <Typography noWrap>
              <DescriptionIcon />
              {row.original.original_filename}
            </Typography>
          </Link>
        </>
      ),
    },
    {
      accessor: 'documentTypes',
      accessorKey: 'documentTypes',
      header: t('documents.table.columns.type', 'Type'),
      minSize: 100,
      Cell: ({ row }) => {
        return (
          <Typography noWrap>
            {row.original.document_types
              .map((type: IDocumentType) => t(type.translationKey, type.name))
              .join(', ')}
          </Typography>
        )
      },
    },
    {
      accessor: 'ourReference',
      accessorKey: 'ourReference',
      header: t('documents.table.columns.shipment', 'Shipment'),
      minSize: 130,
      Cell: ({ row }) => {
        return (
          <Link
            component={RouterLink}
            to={`/shipments/${row.original.shipment.id}`}
            data-testid="document-shipment"
          >
            <Typography noWrap>
              {row.original.shipment?.our_reference ?? '-'}
            </Typography>
          </Link>
        )
      },
    },
    {
      accessor: 'reference',
      accessorKey: 'reference',
      header: t('documents.table.columns.reference', 'Reference'),
      minSize: 130,
      Cell: ({ row }) => {
        return (
          <Typography noWrap>
            {row.original.shipment?.reference ?? '-'}
          </Typography>
        )
      },
    },
    {
      accessor: 'containers',
      accessorKey: 'containers',
      header: t('documents.table.columns.container', 'Container'),
      minSize: 110,
      Cell: ({ row }) => {
        return (
          <Link
            component={RouterLink}
            data-testid="document-shipment-containers"
            to={`/shipments/${row.original.shipment.id}/containers`}
          >
            <Typography noWrap>
              {t('documents.table.cells.container', 'All containers')}
            </Typography>
          </Link>
        )
      },
    },
    {
      accessor: 'booking',
      accessorKey: 'booking',
      header: t('documents.table.columns.shipper', 'Shipper'),
      minSize: 100,
      Cell: ({ row }) => {
        if (row.original?.booking?.shipper_name) {
          return (
            <Link
              component={RouterLink}
              data-testid="document-shipment-booking"
              to={`/shipments/${row.original.shipment.id}/bookings/#${row.original.booking.id}`}
            >
              <Typography noWrap>
                {row.original.booking.shipper_name}
              </Typography>
            </Link>
          )
        }
        return '-'
      },
    },
    {
      accessor: 'createdAt',
      accessorKey: 'createdAt',
      header: t('documents.table.columns.uploaded_at', 'Uploaded at'),
      minSize: 110,
      Cell: ({ row }) => {
        return (
          <Typography noWrap>
            {convertDateToLocalDateTimeWithoutYear(row.original.created_at)}
          </Typography>
        )
      },
    },
    {
      accessor: 'viewableBy',
      accessorKey: 'viewableBy',
      header: t('documents.table.columns.vieviewable_by', 'Viewable by'),
      minSize: 150,
      Cell: ({ row }) => {
        return <DocumentsTableCellViewableBy row={row} />
      },
    },
  ]
}
