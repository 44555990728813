import { OverviewPaginationFilters } from 'src/services/Api/overview/types'
import { searchParams } from 'src/components/Overview/Search/constants'
import { tableStorageKey } from 'src/components/Overview/constants'
import { getOrderParams } from 'src/components/Overview/utils'
import {
  getLocalStorage,
  getStorageKeys,
} from 'src/components/Common/Table/DataTable/TableWrapper.utils'

const storedColumnSorting = getLocalStorage(
  getStorageKeys(tableStorageKey).columnSorting,
  null
)

export const overviewPageName = 'vessel_overview'

export const defaultFilterValues = Object.values(searchParams).reduce(
  (acc, param) => {
    acc[param] = []
    return acc
  },
  { search: '' }
)

export const defaultPagination: OverviewPaginationFilters = {
  order_by: storedColumnSorting ? getOrderParams(storedColumnSorting) : null,
  group_by: 'vessel',
  per_page: 20,
  page: 1,
}

export const defaultValues = {
  ...defaultFilterValues,
  ...defaultPagination,
}

export const pageClassName = 'overview-page'
