import { AnyAction } from 'redux'
import * as actions from '../actions/shipmentDocs'

export const shipmentDocumentsGetData = (id: string): AnyAction => ({
  type: actions.SHIPMENT_DOCUMENTS_GET_DATA,
  id,
})
export const shipmentDocumentsClean = (): AnyAction => ({
  type: actions.SHIPMENT_DOCUMENTS_CLEAN_DATA,
})

export const shipmentDocumentsGetDataSuccess = (payload: any): AnyAction => ({
  type: actions.SHIPMENT_DOCUMENTS_GET_DATA_SUCCESS,
  payload,
})

export const shipmentDocumentsOpenItem = (
  payload: number | null
): AnyAction => ({
  type: actions.SHIPMENT_DOCUMENTS_OPEN_ITEM,
  payload,
})

export const shipmentDocumentSubmitData = (
  shipmentId: number,
  documentId: number,
  payload: any
): AnyAction => ({
  type: actions.SUBMIT_SHIPMENT_DOCUMENT_DATA,
  shipmentId,
  documentId,
  payload,
})

export const shipmentDocumentSubmitDataSuccess = (payload: any): AnyAction => ({
  type: actions.SUBMIT_SHIPMENT_DOCUMENT_DATA_SUCCESS,
  payload,
})

export const deleteShipmentDocument = (
  shipmentId: number,
  documentId: number,
  payload: any
): AnyAction => ({
  type: actions.DELETE_SHIPMENT_DOCUMENT,
  shipmentId,
  documentId,
  payload,
})

export const deleteShipmentDocumentSuccess = (payload: any): AnyAction => ({
  type: actions.DELETE_SHIPMENT_DOCUMENT_SUCCESS,
  payload,
})

export const createShipmentDocument = (
  shipmentId: number,
  payload: any
): AnyAction => ({
  type: actions.CREATE_SHIPMENT_DOCUMENT,
  shipmentId,
  payload,
})

export const createShipmentDocumentSuccess = (payload: any): AnyAction => ({
  type: actions.CREATE_SHIPMENT_DOCUMENT_SUCCESS,
  payload,
})

export const moveShipmentDocument = (
  shipmentId: number,
  documentId: number,
  payload: any
): AnyAction => ({
  type: actions.MOVE_SHIPMENT_DOCUMENT,
  shipmentId,
  documentId,
  payload,
})

export const moveShipmentDocumentSuccess = (payload: any): AnyAction => ({
  type: actions.MOVE_SHIPMENT_DOCUMENT_SUCCESS,
  payload,
})

export const documentTypesGetData = (modality: string): AnyAction => ({
  type: actions.DOCUMENT_TYPES_GET_DATA,
  modality,
})

export const documentTypesGetDataSuccess = (
  payload: IDocType[]
): AnyAction => ({
  type: actions.DOCUMENT_TYPES_GET_DATA_SUCCESS,
  payload,
})
