import { useState, useCallback } from 'react'
import { uniqBy, debounce } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { useGetShipmentAddresses } from 'src/services/Api/shipments'
import { searchParams } from '../constants'
import { FilterOptionValue } from '../types'
import AutocompleteBase from './AutocompleteBase'

const BookingPartiesAutocompleteAsync = ({ token, onDelete }) => {
  const {
    fetchAsync: getAddresses,
    isFetched,
    isFetching,
  } = useGetShipmentAddresses()

  const addressType =
    token.value === searchParams.consignee ? 'consignee' : 'shipper'

  const { getValues } = useFormContext()
  const defaultValue = getValues(token.value) ?? []

  const [options, setOptions] = useState<FilterOptionValue[]>(defaultValue)

  const [inputValue, setInputValue] = useState('')

  const handleDebounceFn = async (input: string) => {
    setInputValue(input)
    const hasOption = options.find((option) => option.label === input)
    if (input.length === 0 || !!hasOption) {
      return
    }
    const data = await getAddresses({
      address_type: addressType,
      search: input,
      per_page: 200,
      page: 1,
    })

    const addresses: FilterOptionValue[] = (data ?? []).map((address) => ({
      label: address,
      value: address,
    }))

    setOptions(uniqBy([...defaultValue, ...addresses], 'value'))
  }

  const handleInputChangeWithDebounce = useCallback(
    debounce(handleDebounceFn, 800),
    [defaultValue]
  )
  const handleOnInputChange = (search: string) => {
    handleInputChangeWithDebounce(search)
  }

  const handleDelete = () => {
    onDelete(token.value)
  }

  const noOptionsText =
    isFetched && inputValue.length > 0 ? 'No options' : 'Start typing...'

  return (
    <AutocompleteBase
      optionKey="value"
      options={options}
      noOptionsText={noOptionsText}
      token={token}
      asynchronous={true}
      defaultValue={defaultValue}
      loading={isFetching}
      onDelete={handleDelete}
      onInputChange={handleOnInputChange}
    />
  )
}

export default BookingPartiesAutocompleteAsync
