import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import muiTheme from 'src/muiTheme'
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles'
import { useState, ChangeEvent } from 'react'
import { Box } from '@mui/material'
import { ShipmentContent } from 'src/stories/MUI/ShipmentContent'
import Pagination from '@mui/material/Pagination'
import { TooltipProps, TooltipContentProps } from './MapTooltip.props'
import MapTooltipFooter from './MapTooltipFooter'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface MapTooltipProps {
  shipments: TooltipProps[]
}

const MapTooltip: React.FC<MapTooltipProps> = ({ shipments }) => {
  const shipment = shipments[0]
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const pageCount = shipments.length
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <Stack
          spacing={2}
          width={255}
          divider={<Divider orientation="horizontal" flexItem />}
        >
          <Box>
            {shipments.map((shipment: TooltipContentProps, index: number) => {
              const {
                shared_reference: sharedRef,
                organization_reference: internalRef,
              } = shipment
              return (
                <Box
                  key={shipment.id}
                  sx={{ display: index === activeIndex ? 'block' : 'none' }}
                >
                  <ShipmentContent
                    {...{
                      showLegs: false,
                      direction: 'column',
                      data: {
                        id: `${shipment.id}`,
                        title: shipment.title,
                        modality: shipment.modality,
                        shared_reference: !!sharedRef ? sharedRef : '-',
                        internal_reference: !!internalRef ? internalRef : '-',
                        visibility_only: shipment.visibility_only,
                      },
                    }}
                  />
                </Box>
              )
            })}
            <Divider sx={{ mb: 2, mt: 2 }} />
            <MapTooltipFooter shipment={shipment} />
            {pageCount > 1 && (
              <Pagination
                siblingCount={0}
                size="small"
                color="primary"
                count={pageCount}
                sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
                onChange={(_event: ChangeEvent<unknown>, page: number) => {
                  setActiveIndex(page - 1)
                }}
              />
            )}
          </Box>
        </Stack>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default MapTooltip
