import { ParsedQuery } from 'query-string'

interface IRequestPreparation {
  formData: FormData
  valid: boolean
}

export const prepareForRequest = (
  state: IChatComponentState
): IRequestPreparation => {
  const formData = new FormData()
  const valid: boolean = !!(state.content || state.filesToUpload.length)

  formData.append('chat_comment[content]', state.content)
  if (state.replyCommentId) {
    formData.append(
      'chat_comment[answered_comment_id]',
      state.replyCommentId.toString()
    )
  }
  state.filesToUpload.forEach((file, i) => {
    formData.append(
      `chat_comment[comment_attachments_attributes][${i}][file]`,
      file
    )
  })

  return { formData, valid }
}

export const getRequestRateMatchMessageData = (params: ParsedQuery<string>) => {
  const requiredKeys = ['rate', 'validity', 'currency', 'carrier', 'forwarder']
  const hasRequiredKeys = requiredKeys.every(
    (key) => Object.keys(params).includes(key) && !!params[key]
  )
  if (hasRequiredKeys) {
    const formData = new FormData()
    const { currency = null } = params
    const currencyText =
      typeof currency === 'string' ? currency.toUpperCase() : ''

    const content = `Hi, I would like to request a rate match for the proposed quote based on the below information.\nCarrier: ${params.carrier}\nForwarder: ${params.forwarder}\nTarget rate: ${params.rate}\nCurrency: ${currencyText}\nValid till: ${params.validity}`

    formData.append('chat_comment[content]', content)

    return formData
  }

  return null
}
