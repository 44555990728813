import { useGetSigmaIframeUrl } from 'src/services/Api/sigma'

const Sigma = () => {
  const { data } = useGetSigmaIframeUrl()

  if (!data?.data?.url) return null

  return (
    <iframe
      id="sigmaDashboard"
      title="sigma"
      src={data.data.url}
      width="100%"
      height="100%"
      style={{ height: '100vh', border: 'none' }}
    />
  )
}

export default Sigma
