import { createReducer, Reducer } from 'redux-create-reducer'

import { AnyAction } from 'redux'
import { cloneDeep } from 'lodash'
import * as actions from '../actions/shipmentDocs'

declare global {
  interface IShipmentDocument {
    description: string
    id: number
    original_filename: string
    download_url: string
    types: IDocType[]
    document_types: IDocumentType[]
    created_at: string
    url: string
    containers: IContainerShort[]
    booking: any
    shipment: IShipment
    uploaded_by: {
      id: number
      email: string
      full_name: string
      original_filename: string
      organization_name: string
    }
    document_manageable: boolean
    viewable_by: IOrganization[]
  }

  interface IShipmentDocsState {
    shipmentDocumentsData: any
    shipmentDocumentTypes: IDocType[]
    containerDocumentTypes: IDocType[]
    documentTypes: IDocType[]
    openItem: number | null
  }

  interface IDocType {
    translationKey: string
    id: number
    name: string
    code: string
    container_level: boolean
    booking_level: boolean
    visible_for_role: boolean
    created_at: string
    extract_data_enabled: boolean
    organization_level: boolean
    shipment_level: boolean
    updated_at: string
    restricted_role_codes: string[]
  }
}

export const initialShipmentDocsState = {
  shipmentDocumentsData: {},
  shipmentDocumentTypes: [],
  containerDocumentTypes: [],
  documentTypes: [],
  openItem: null,
}

const receiveShipmentDocuments: Reducer<IShipmentDocsState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  shipmentDocumentsData: action.payload,
})

const cleanShipmentDocuments: Reducer<IShipmentDocsState, AnyAction> = (
  state
) => ({
  ...state,
  shipmentDocumentsData: initialShipmentDocsState,
})

const receiveDocumentTypes: Reducer<IShipmentDocsState, AnyAction> = (
  state,
  action
) => {
  const documentTypes = action.payload
  return {
    ...state,
    documentTypes: documentTypes.map((doc) => ({
      translationKey: `document_types.types.${doc.code}`,
      ...doc,
    })),
  }
}

const setOpenItem: Reducer<IShipmentDocsState, AnyAction> = (
  state,
  action
) => ({
  ...state,
  openItem: action.payload,
})

const receiveUpdatedShipmentDocument: Reducer<IShipmentDocsState, AnyAction> = (
  state,
  action
) => {
  const shipmentDocumentsData = cloneDeep(state.shipmentDocumentsData)

  if (shipmentDocumentsData && shipmentDocumentsData.shipment_documents) {
    shipmentDocumentsData.shipment_documents.forEach((item, index) => {
      if (item.id === action.payload.id) {
        shipmentDocumentsData.shipment_documents[index] = action.payload
      }
    })
  }

  return {
    ...state,
    shipmentDocumentsData,
  }
}

const deleteShipmentDocument: Reducer<IShipmentDocsState, AnyAction> = (
  state,
  action
) => {
  const shipmentDocumentsData = cloneDeep(state.shipmentDocumentsData)

  if (shipmentDocumentsData && shipmentDocumentsData.shipment_documents) {
    shipmentDocumentsData.shipment_documents = shipmentDocumentsData.shipment_documents.filter(
      (item) => item.id !== action.payload.documentId
    )
  }

  return {
    ...state,
    shipmentDocumentsData,
  }
}

const createShipmentDocument: Reducer<IShipmentDocsState, AnyAction> = (
  state,
  action
) => {
  const shipmentDocumentsData = cloneDeep(state.shipmentDocumentsData)

  if (shipmentDocumentsData.shipment_documents) {
    shipmentDocumentsData.shipment_documents.unshift(action.payload)
  }

  return {
    ...state,
    shipmentDocumentsData,
  }
}

const moveShipmentDocument: Reducer<IShipmentDocsState, AnyAction> = (
  state,
  action
) => {
  const shipmentDocumentsData = cloneDeep(state.shipmentDocumentsData)
  const container = shipmentDocumentsData.containers.find(
    (item) => item.id === action.payload.data.container_id
  )

  shipmentDocumentsData.shipment_documents = shipmentDocumentsData.shipment_documents.filter(
    (item) => item.id !== action.payload.action.documentId
  )

  if (container != null) {
    container.container_documents.unshift(action.payload.data)
  }

  return {
    ...state,
    shipmentDocumentsData,
  }
}

export default createReducer(initialShipmentDocsState, {
  [actions.SHIPMENT_DOCUMENTS_GET_DATA_SUCCESS]: receiveShipmentDocuments,
  [actions.SHIPMENT_DOCUMENTS_CLEAN_DATA]: cleanShipmentDocuments,
  [actions.SUBMIT_SHIPMENT_DOCUMENT_DATA_SUCCESS]: receiveUpdatedShipmentDocument,
  [actions.DELETE_SHIPMENT_DOCUMENT_SUCCESS]: deleteShipmentDocument,
  [actions.CREATE_SHIPMENT_DOCUMENT_SUCCESS]: createShipmentDocument,
  [actions.MOVE_SHIPMENT_DOCUMENT_SUCCESS]: moveShipmentDocument,
  [actions.DOCUMENT_TYPES_GET_DATA_SUCCESS]: receiveDocumentTypes,
  [actions.SHIPMENT_DOCUMENTS_OPEN_ITEM]: setOpenItem,
})
