import { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useTranslation } from 'react-i18next'

const OverviewViewsAdd = ({
  open,
  onClose,
  onSave,
  title,
  description,
  defaultValue = '',
}) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(defaultValue)
  const handleSave = () => {
    onSave(value)
    setValue('')
  }
  const handleCancel = () => {
    onClose()
    setValue('')
  }

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        <TextField
          placeholder={t(
            'overview_views.add_view.input_placeholder',
            'Descriptive name'
          )}
          variant="outlined"
          margin="dense"
          name="name"
          id="name"
          autoFocus
          required
          fullWidth
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={handleCancel}>
          {t('common.buttons.cancel', 'Cancel')}
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={handleSave}
          disabled={value.length === 0}
        >
          {t('common.buttons.save', 'Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OverviewViewsAdd
