import { useEffect, useState, useCallback } from 'react'
import debounce from 'lodash/debounce'
import { makeStyles, createStyles } from '@mui/styles'
import MuiAutocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { InputLabel, Typography } from '@mui/material'

export interface SearchBarProps<T> {
  disableClearable?: boolean
  onChange: (option: T | null) => void
  /**
   * Field placeholder
   */
  placeholder?: string
  required?: boolean
  value: T | null
  options?: T[]
  /**
   * Callback to get options asynchronously
   */
  getData?: (searchQuery: string) => Promise<T[]>
  /**
   * A field that will be used to display an option in the dropdown
   */
  optionLabel?: (option: T) => string
  /**
   * A function to determine how to divide options into groups
   */
  groupBy?: (option: T) => string
  /**
   * Error state of component
   */
  error?: boolean
  children?: (text: React.ReactNode, option: T) => React.ReactNode
  id?: string
  label?: string
  optionsKey?: string
  onInputChange?: (event: any, value: string) => void
  filterResults?: boolean
  popperWidth?: number | string
}

const Autocomplete = <T extends Record<string, any>>({
  placeholder = 'Start typing to search',
  optionLabel = (option) => option.name,
  error = false,
  optionsKey,
  required,
  getData,
  filterResults = true,
  popperWidth,
  ...props
}: SearchBarProps<T>) => {
  const [isLoading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<T[]>(props.options || [])
  const classes = useStyles()
  const autocompleteProps = filterResults
    ? {}
    : {
        filterOptions: (x) => x,
      }

  const getOptions = useCallback(
    async (query: string) => {
      if (!getData) return Promise.resolve()
      setLoading(true)
      try {
        const options = await getData(query)
        setOptions(optionsKey ? options[optionsKey] : options)
      } finally {
        setLoading(false)
      }
    },
    [props]
  )

  const onInputChange = useCallback(
    debounce((_event, newValue: string) => {
      props.onInputChange?.(_event, newValue)
      if (newValue === '') props.onChange(null)
      setInputValue(newValue)
    }, 500),
    []
  )

  const onChange = useCallback(
    (_event: React.ChangeEvent<{}>, option: T | null) => {
      props.onChange(option)
    },
    []
  )

  useEffect(() => {
    if (inputValue === (props.value && optionLabel(props.value))) {
      return
    }

    if (props.options && props.options.length) {
      setOptions(props.options)
    }

    getOptions(inputValue)
  }, [inputValue, props.value, props.options])

  const endAdornment = (
    <SearchRoundedIcon className={classes.arrowIcon} color="inherit" />
  )

  return (
    <MuiAutocomplete
      {...props}
      isOptionEqualToValue={(option, value) =>
        optionLabel(option) === optionLabel(value)
      }
      getOptionLabel={optionLabel}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      popupIcon={endAdornment}
      id={props.id}
      onChange={onChange}
      loading={isLoading}
      autoHighlight
      value={props.value as any}
      options={options}
      onInputChange={onInputChange}
      groupBy={props.groupBy}
      classes={{
        root: classes.root,
      }}
      renderInput={(params) => {
        return (
          <form autoComplete={'new-password'}>
            {props.label && (
              <InputLabel htmlFor={props.id} required={required}>
                {props.label}
              </InputLabel>
            )}
            <TextField
              {...params}
              placeholder={placeholder}
              error={error}
              inputProps={{
                ...params.inputProps,
                'data-testid': 'search-bar',
              }}
            />
          </form>
        )
      }}
      renderOption={(optionProps, option, { inputValue }) => {
        const matches = match(optionLabel(option), inputValue)
        const parts = parse(optionLabel(option), matches)

        const OptionText = (
          <Typography noWrap py={0.5}>
            {parts.map((part, index: number) => (
              <span
                key={index}
                style={{
                  fontWeight: part.highlight ? 600 : 400,
                }}
              >
                {part.text}
              </span>
            ))}
          </Typography>
        )

        return (
          <li {...optionProps} data-testid="search-bar-select-option">
            {props.children && props.children(OptionText, option)}
            {!props.children && OptionText}
          </li>
        )
      }}
      componentsProps={{
        popper: popperWidth
          ? { style: { width: popperWidth }, placement: 'bottom-start' }
          : {},
      }}
      {...autocompleteProps}
    />
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiAutocomplete-input': {
        width: '100% !important',
      },
      '& .MuiOutlinedInput-root': {
        padding: '0 12px',
      },
      '& .MuiAutocomplete-popupIndicator': {
        transform: 'none',
      },
    },
    arrowIcon: {
      color: theme.palette.grey[500],
    },
  })
)

export default Autocomplete
