import {
  useState,
  createContext,
  forwardRef,
  useContext,
  useRef,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { VariableSizeList } from 'react-window'
import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteCloseReason,
} from '@mui/material/Autocomplete'
import FormLabel from 'src/stories/Lab/FormLabel'
import {
  AutocompleteOptionProps,
  PopperComponentProps,
  AutocompleteProps,
} from './Autocomplete.props'
import {
  renderRow,
  listBoxPadding,
  getSortedOptions,
  getRenderedInput,
} from './Autocomplete.utils'
import {
  StyledAutocompletePopper,
  StyledTextField,
  StyledPopper,
} from './AutocompleteCommon.styles'
import AutocompleteCommonToggleEndAdornment from './AutocompleteCommonToggleEndAdornment'

const PopperComponent = (props: PopperComponentProps) => {
  const { disablePortal, anchorEl, open, ...other } = props
  return <StyledAutocompletePopper {...other} />
}

const OuterElementContext = createContext({})

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

const useResetCache = (data: number) => {
  const ref = useRef<VariableSizeList>(null)
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

const ListboxComponent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData: React.ReactChild[] = []
  ;(children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item)
      itemData.push(...(item.children || []))
    }
  )

  const itemCount = itemData.length
  const itemSize = 40

  const getChildSize = () => {
    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * listBoxPadding}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="div"
          itemSize={() => getChildSize()}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

const AutocompleteCommon = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
) => {
  const { t } = useTranslation()
  const { value, onReset, onChange, label } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [options, setOptions] = useState<AutocompleteOptionProps[]>([])
  const open = Boolean(anchorEl)

  useEffect(() => {
    setOptions(getSortedOptions(props.options, value))
  }, [open])

  const inputValue =
    value.length === 0
      ? props.placeholder || 'Select'
      : value.map((selected) => selected.label).join(', ')

  const inputClass = value.length === 0 ? 'empty' : ''

  const onInputClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = () => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
    setOptions(getSortedOptions(props.options, value))
  }

  const handleOnChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: AutocompleteOptionProps[],
    reason: string,
    details?: AutocompleteChangeDetails<AutocompleteOptionProps>
  ) => {
    if (
      event.type === 'keydown' &&
      (event as React.KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return
    }

    onChange(newValue, details)
  }

  const handleOnClose = (
    _event: React.ChangeEvent<{}>,
    reason: AutocompleteCloseReason
  ) => {
    if (reason === 'escape') {
      onClose()
    }
  }

  const onClearClicked = (event: { stopPropagation: () => void }) => {
    event.stopPropagation()
    onReset()
  }

  const optionsHaveGroups = props.options.some((option) => option.group)

  const groupBy = optionsHaveGroups
    ? (option: AutocompleteOptionProps) => {
        return option?.group ?? ''
      }
    : undefined

  return (
    <Box sx={{ position: 'relative' }}>
      {label && <FormLabel label={label} count={value.length} />}
      <StyledTextField
        inputProps={{
          readOnly: true,
          className: inputClass,
          'data-testid': 'autocomplete-common-value-input',
        }}
        InputProps={{
          endAdornment: (
            <AutocompleteCommonToggleEndAdornment
              open={open}
              onClick={onClearClicked}
              hasValue={value && value.length > 0}
            />
          ),
        }}
        value={inputValue}
        className="trigger"
        onClick={onInputClick}
        data-testid={props['data-testid'] || 'autocomplete-common'}
        fullWidth
      />
      <StyledPopper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal={props.disablePortal}
      >
        <ClickAwayListener onClickAway={onClose}>
          <div>
            <Autocomplete
              open
              fullWidth
              value={value}
              multiple={true}
              autoComplete={true}
              disableCloseOnSelect
              disableListWrap={true}
              renderTags={() => null}
              onClose={handleOnClose}
              options={options}
              onChange={handleOnChange}
              noOptionsText="No options"
              // TODO: Post React 18 update - validate this conversion, look like a hidden bug
              renderGroup={(params) =>
                (({
                  ...params,
                  group: t(params.group),
                } as unknown) as React.ReactNode)
              }
              groupBy={groupBy}
              ListboxComponent={ListboxComponent}
              renderInput={getRenderedInput}
              renderOption={(props, option) =>
                [props, option] as React.ReactNode
              }
              PopperComponent={PopperComponent}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </Box>
  )
}

export default AutocompleteCommon
