import { useMemo, memo, useCallback } from 'react'
import debounce from 'lodash/debounce'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import TableWrapper from 'src/components/Common/Table/DataTable/TableWrapper'
import { TableStateProps } from 'src/components/Common/Table/DataTable/TableWrapper.props'
import { useUpdateSavedFilter } from 'src/services/Api/savedFilters'
import { getLocalStorage } from '../Common/Table/DataTable/TableWrapper.utils'
import { getTableColumns } from './table'
import {
  tableStorageKey,
  defaultColumnOrder,
  defaultColumnVisibility,
  savedViewLocalStorageKey,
} from './constants'
import WrappedTable from './ContentTableColumns'
import {
  getLocallyStoredView,
  getNewView,
  handleUpdateViewSuccess,
} from './utils'

const ContentTable = () => {
  const { getValues } = useFormContext()
  const filters = getValues()
  const savedViewId = getLocalStorage(savedViewLocalStorageKey, null)
  const { mutateAsync: updateSavedFilter } = useUpdateSavedFilter(savedViewId)
  const { t } = useTranslation()
  const savedView = getLocallyStoredView()
  const columns = useMemo(() => {
    return getTableColumns(t)
  }, [t])

  const updateView = (requestBody) => {
    updateSavedFilter(requestBody).then((res: ShipmentQuickFilter) => {
      handleUpdateViewSuccess(res, savedViewId)
    })
  }

  const saveTableChanges = (newTableState: TableStateProps) => {
    if (!savedView) {
      return
    }
    const newSavedView = getNewView(savedView?.name ?? '', filters)
    if (!newSavedView?.table) {
      return
    }
    const newTable = {
      table: { ...newSavedView.table, ...newTableState },
    }
    updateView({
      ...newSavedView,
      ...newTable,
    })
  }

  const saveWithDebounce = useCallback(debounce(saveTableChanges, 1000), [
    savedView,
    filters,
  ])

  return (
    <TableWrapper
      columns={columns}
      data-testid="overview-table"
      tableStorageKey={tableStorageKey}
      defaultColumnOrder={defaultColumnOrder}
      defaultColumnVisibility={defaultColumnVisibility}
      ComponentProps={{
        elevation: 0,
        sx: {
          height: '100%',
          overflowY: 'hidden',
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          position: 'relative',
        },
      }}
      WrappedTable={WrappedTable}
      onTableStateChange={saveWithDebounce}
    />
  )
}

export default memo(ContentTable)
