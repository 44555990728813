import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'
import OverviewTableText from './OverviewTableText'

const OverviewTableShipmentBookingParties = ({ cell }) => {
  const text = uniqBy(orderBy(cell.getValue(), ['name'], ['asc']), 'name')
    .map((bookingParty) => bookingParty.name)
    .join(', ')
  return <OverviewTableText text={text.length > 0 ? text : '-'} />
}

export default OverviewTableShipmentBookingParties
