import { FunctionComponent } from 'react'

import { Box, Typography, Button, Skeleton } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { useTranslation } from 'react-i18next'

interface IProps {
  onAddShipmentGroup: () => void
}
const EmptyState: FunctionComponent<IProps> = ({ onAddShipmentGroup }) => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        backgroundColor: '#F2F2F3',
        borderRadius: '10px',
        padding: 3,
        maxWidth: '755px',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <VisibilityIcon />
        <Typography
          variant="h5"
          ml={1}
          children={t(
            'account.tabs.shipments.shipment_groups.title',
            'Create your first custom shipment group'
          )}
        />
        <Button
          variant="contained"
          sx={{ marginLeft: 'auto' }}
          onClick={onAddShipmentGroup}
          startIcon={<AddRoundedIcon />}
          size="large"
        >
          {t(
            'account.tabs.shipments.shipment_groups.add_shipment_group',
            'Add shipment group'
          )}
        </Button>
      </Box>
      <Skeleton width="40%" sx={{ borderRadius: 1 }} />
      <Skeleton width="70%" sx={{ borderRadius: 1 }} />
      <Skeleton width="20%" sx={{ borderRadius: 1 }} />
      <Skeleton width="30%" sx={{ borderRadius: 1 }} />
    </Box>
  )
}

export default EmptyState
