import {
  useMutation,
  UseMutationOptions,
  UseQueryOptions,
  useQuery,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  ICreateFilterRequest,
  ICreateFilterResponse,
  IDeleteFilterResponse,
  ISaveFiltersRequest,
  ISaveFiltersResponse,
  IUpdateFilterRequest,
  IUpdateFilterResponse,
} from 'src/@types/endpoints/other'
import { createQueryString } from 'src/services/utils/index'
import { apiClient } from 'src/services/http-common'
import endpoints, { queryKeys } from '../endpoints'
import { useQueryAsync } from '../hooks'

export const useGetQuickFiltersAsync = (
  options?: UseQueryOptions<
    ISaveFiltersResponse,
    AxiosError,
    ISaveFiltersResponse
  >
) => {
  const { savedFilters: key } = queryKeys

  const getFilters = (payload) => {
    const queryString = createQueryString(payload, {
      skipEmptyString: false,
    })
    const url = endpoints.savedFilters + queryString
    return apiClient.get<any, ISaveFiltersResponse>(url)
  }

  return useQueryAsync([key], getFilters, options)
}

export const useGetQuickFilters = (
  payload: any,
  options?: UseQueryOptions<
    ISaveFiltersResponse,
    AxiosError,
    ISaveFiltersResponse
  >
) => {
  const { savedFilters: key } = queryKeys

  const queryString = createQueryString(payload, {
    skipEmptyString: false,
  })

  const getSavedFilters = () => {
    const url = endpoints.savedFilters + queryString

    return apiClient.get<any, ISaveFiltersResponse>(url)
  }

  return useQuery<ISaveFiltersResponse, AxiosError>(
    [key],
    getSavedFilters,
    options
  )
}

export const useUpdateSavedFilters = (
  options?: UseMutationOptions<
    ISaveFiltersResponse,
    AxiosError,
    ISaveFiltersRequest
  >
) => {
  const updateSaveFilters = (payload: ISaveFiltersRequest) => {
    const url = endpoints.savedFiltersBulkUpdate

    return apiClient.put<any, ISaveFiltersResponse>(
      url,
      JSON.stringify(payload)
    )
  }

  return useMutation(updateSaveFilters, options)
}

export const useCreateNewFilter = (
  options?: UseMutationOptions<
    ICreateFilterResponse,
    AxiosError,
    ICreateFilterRequest
  >
) => {
  const createNewFilter = (payload: ICreateFilterRequest) => {
    const url = endpoints.savedFilters

    return apiClient.post<any, ICreateFilterResponse>(
      url,
      JSON.stringify(payload)
    )
  }

  return useMutation(createNewFilter, options)
}

export const useUpdateSavedFilter = (
  filterId: number | null,
  options?: UseMutationOptions<
    IUpdateFilterResponse,
    AxiosError,
    IUpdateFilterRequest
  >
) => {
  const updateSavedFilter = (payload: IUpdateFilterRequest) => {
    const url = `${endpoints.savedFilters}/${filterId}`

    return apiClient.put<any, IUpdateFilterResponse>(
      url,
      JSON.stringify(payload)
    )
  }

  return useMutation(updateSavedFilter, options)
}

export const useDeleteSavedFilter = (
  options?: UseMutationOptions<IDeleteFilterResponse, AxiosError, number | null>
) => {
  const deleteSavedFilter = (filterId: number | null) => {
    const url = `${endpoints.savedFilters}/${filterId}`
    return apiClient.delete<any, IDeleteFilterResponse>(url)
  }

  return useMutation(deleteSavedFilter, options)
}
